{
	"CHARTS": {
		"last1months": "1m",
		"last3months": "3m",
		"last6months": "6m",
		"last12months": "12m",
		"last24months": "24m"
	},
	"VALIDATIONS": {
		"error": "Erreur, controlez les données",
		"minimun_one_adult_or_Child_is_required": "Vous avez besoin d'au moins un adulte ou un enfant",
		"error_tenant_does_not_exist": "l'opérateur n'existe pas",
		"not_possible": "cette action n'est pas possible",
		"required": "champ obligatoire",
		"tenant_is_required": "opérateur est un champ obligatoire",
		"email_is_required": "email est un champ obligatoire",
		"wrong_password": "wrong password",
		"check_email": "a password reset link was sent",
		"International_phone_format": "format international expecté",
		"email": "email n'est pas valide",
		"duplicate_email": "adresse email dupliquée, action non valide",
		"no_integer": "ce n’est pas un nombre entier valide",
		"token_has_expired": "votre session est écoulée",
		"wrong_credentials": "identifiant non reconnu",
		"date_later_than_today": "la date demandée doit être plus tard qu'il y a 90 jours",
		"save_or_cancel_updates": "enregistrez ou annulez tes changements",
		"warning": "attention",
		"capacity_must_be_between_0_and_30": "la capacité doit être entre 0 et 100",
		"validation_errors": "corrigez d'abord les erreurs",
		"mismatch_location": "la base de vol ne situe pas dans la région de la réservation",
		"mismatch_availability": "temps de vol ne correspond pas avec la disponibilité de la réservation",
		"mismatch_start_date": "date de vol est plus tôt que le date de début de la réservation",
		"mismatch_end_date": "date de vol est plus tard que le date de fin de la réservation",
		"mismatch_type": "type de vol ne correspond pas avec le type de la réservation",
		"mismatch_flight": "ajouté à un autre vol",
		"missing_function": "cette fonction n'existe pas encore",
		"no_category": "pas de categories passagers trouvées, ajoutez 1 ou plusieures catégories dans vos préférences.",
		"no_region": "pas de région trouvé, ajoutez d'abord 1 ou plusieurs régions.",
		"lift_mismatch": "pas possible de calculer le lift et ce dû à un manque de données",
		"lift_missing_location": "donnée endroit de décollage manquant",
		"lift_missing_balloon_volume": "donnée volume du ballon manquant",
		"lift_missing_balloon_weight": "donnée poids du ballon manquant ou incomplet",
		"lift_missing_pilot": "donnée pilote inconnu/manquant",
		"lift_missing_pilot_weight": "donnée poids du pilote inconnu/manquant",
		"lift_missing_temp_takeoff": "donnée température au décollage manquante",
		"lift_missing_pressure": "donnée pression au point de décollage manquante",
		"mobile_missing_signature": "tous les passagers ne sont pas enregistrés/manque signature",
		"mobile_missing_forecast": "données météo manquantes",
		"mobile_documents": "dates de validité",
		"mobile_doc_insurance": "assurance valide jusqu'à",
		"mobile_doc_airworthiness": "certificat de navigabilité valide jusqu'au",
		"mobile_doc_license": "license de pilot valide jusqu'à",
		"mobile_clear": "effacer",
		"mobile_agree": "sauvegarder",
		"mobile_self_sign": "signer X",
		"click_to_fly": "cliquez ici pour voler",
		"pax_good_to_fly": "pax ok",
		"lift_good_to_fly": "lift ok",
		"fuel_good_to_fly": "fuel ok",
		"weather_good_to_fly": "météo ok",
		"docs_good_to_fly": "documents ok",
		"pilot_sign_off": "confirmez tous les paramètres et signez. Des commentaires peuvent être ajouter.",
		"agree": "pour accord",
		"redo": "éffacer",
		"new_version": "nouvelle version disponible",
		"reload_the_app": "désirez vous redémarrez Quick Release maintenant ?",
		"new_features_available": "{0} nouvelles fonctionnalités disponibles",
		"show_new_features": "montrer les nouvelles fonctionnalités",
		"bookings_with_open_amount": "réservations avec solde dû",
		"bookings_to_confirm": "à confirmer",
		"bookings_bookmarked": "marquées",
		"cylinder_capacity_must_be_between_0_and_200": "la capacité doit être entre 0 et 200",
		"cylinder_weight_must_be_between_0_and_200": "le poids doit être entre 0 et 200",
		"unauthorized": "vous n'êtes pas authorizé pour cette opération",
		"disconnected": "problème avec la connection, connectez-vous à l'internet",
		"possible_duplicates": "attention, réservation double possible, nous avons trouvés une réservation ouverte avec le même addresse e-mail",
		"possible_duplicates_with_flights": "attention, réservation double possible sur un autre vol",
		"duplicate_flight": "ballon déjà planifié",
		"duplicate_pilot": "pilote déjà planifié"
	},
	"HINTS": {
		"add_country_code": "ajouter le code international du pays +32...",
		"no_contact_selected": "pas de donnée de contact sélectionnée",
		"message_filter": "filtre on/off",
		"role_can_only_be_set_after_creation": "le role ne peut être sélectionner après la création",
		"please_wait": "patience s.v.p."
	},
	"SKEYES": {
		"ballooning_bulletin": "Ballooning Bulletin",
		"load": "Skeyes login",
		"disclaimer": "Ballooning Bulleting est un service de Skeyes.",
		"instructions": "Entrez votre login et mot de passe Skeyes pour consulter le Ballooning Bulletin. Seuls les pilotes avec une licence valide peuvent consulter le Ballooning Bulletin. Les données sont uniquement utilisées pour consulter le Ballooning Bulletin et ne sont pas enregistrées. Les conditions d'utilisation de Skeyes s'appliquent.",
		"username": "Skeyes Username",
		"password": "Skeyes Password",
		"loaded_by": "Chargé par",
		"loading_can_take_up_to_15sec": "Chargement peut prendre jusqu'à 15 secondes",
		"error": "erreur inattendue, vérifiez tous les champs",
		"slot_warning": "ce bulletin n'est peut-être pas applicable pour ce vol"
	},
	"APPLICATION": {
		"domain": "domaine",
		"Du": "nederlands (Nederland)",
		"Nl": "nederlands (België)",
		"Fr": "français",
		"En": "anglais (english)",
		"Es": "español (spanish)",
		"Cs": "cesky (czech)",
		"It": "Italiano (Italian)",
		"De": "Deutsch (German)",
		"Pt": "Português (portuguese)",
		"Lv": "Latviešu (Latvian)",
		"date": "date",
		"name": "nom d'utilisateur",
		"call_sign": "immatriculation",
		"capacity": "capacité",
		"flight_count": "vols",
		"sku": "sku",
		"unit_count": "nombre",
		"unit_cost": "prix unitaire",
		"send_sms": "sms envoyés",
		"receive_sms": "sms reçus",
		"sms_count": "sms",
		"payments": "paiements",
		"running_month": "mois en cours",
		"history_balloons": "historique ballons",
		"history_sms": "histoirique sms",
		"total_cost": "prix",
		"sorry": "excuses",
		"flights": "vols",
		"calendar": "calendrier",
		"vouchers": "bons cadeau",
		"bookings": "réservations",
		"balloons": "ballons",
		"crew": "crew et pilotes",
		"regions": "régions",
		"locations": "bases de décollage",
		"reports": "rapports",
		"admin": "gestion",
		"releasenotes": "release notes",
		"subscription": "abonnement",
		"log_sms_in": "sms entrant",
		"log_sms_out": "sms sortant",
		"settings": "préférences",
		"cylinders": "cylinders",
		"language": "langue",
		"sms": "sms",
		"help": "aide",
		"search": "chercher",
		"live_filter_off": "Utilisez le bouton de recherche pour rechercher. Si plus de 25 résultats de recherche doivent être affichés, la recherche en direct n’est pas possible",
		"add_booking": "ajouter réservation",
		"add_voucher": "ajouter un bon",
		"add_flight": "ajouter un vol",
		"privacy_policy": "politique de confidentialité",
		"terms": "conditions d'utilisation",
		"sign in to use your Quick Release Account": "Identifiez-vous",
		"your username and password didn't match": "votre nom d'utilisateur et mot de passe ne correspondent pas",
		"please try again": "veuillez réessayer",
		"username": "nom d'utilisateur",
		"password": "mot de passe",
		"sign_in": "connexion",
		"send_PIN": "envoyer PIN",
		"use_PIN": "accorder PIN",
		"pin_successfully_sent": "PIN envoyé avec succès",
		"use_password": "password",
		"send_email": "envoyer email",
		"email": "email",
		"send_email_to": "sélectionner le destinataire",
		"select_template": "sélectionner template",
		"sent": "envoyé le",
		"waiting": "en cours de transmission",
		"pin": "PIN",
		"sign_out": "déconnexion",
		"save": "enregistrer",
		"save_and_back": "enregistrer et retourner",
		"cancel": "annuler",
		"previous": "précédente",
		"delete": "supprimer",
		"refresh": "renouveller",
		"today": "aujourd'hui",
		"classic": "interface classique",
		"month": "mois",
		"week": "semaine",
		"morning_flights": "vols ce matin",
		"evening_flights": "vols ce soir",
		"morning_flights_tomorrow": "vols le lendemain matin",
		"yesterday_flights": "vols de hier soir",
		"action": "action",
		"version": "version",
		"hide_details": "masquer détails",
		"loads_faster": "masquer les détails augmente la vitesse de la page vols",
		"assign": "attribuer",
		"assigned": "attribué",
		"assign_to": "attribuer un vol à",
		"close": "fermer",
		"apps": "ressources"
	},
	"CALENDAR": {
		"waiting_list": "list d'attente",
		"no_bookings_yet": "aucune réservation pas encore",
		"drag_and_drop_reservations_here_to_add_to_this_flight": "glissez et déposez des réservations ici pour ajouter au vol",
		"drag_and_drop_reservations_here_to_remove_from_this_flight": "glissez et déposez des réservations ici pour supprimer du vol",
		"good_job": "bien travailler!",
		"all_bookings_are_added_to_a_flight": "toutes les réservations sont ajoutées à un vol",
		"you_cannot_drop_bookings_here": "vous ne pouvez pas déposer des réservations ici",
		"show_calendar_crew": "voir le planning crew",
		"set_month": "définir le mois",
		"clear_month": "supprimer tout",
		"plan": "plan",
		"refetch": "actualiser"
	},
	"ACTORS": {
		"COM": "CPL",
		"NCO": "PPL",
		"NOL": "pas de license",
		"NOA": "pas d'accès",
		"OPR": "opérateur",
		"CRE": "pilot/crew (vols assignés)",
		"REP": "report viewer",
		"ASS": "Operator assistant (vols & réservations)",
		"MET": "météo (vols)",
		"user_role": "fonction",
		"name": "nom",
		"phone": "téléphone ",
		"email": "email",
		"used_for_login": "utilisé pour la connexion",
		"emailNotifications": "notifications par e-mail",
		"emails_from_the_system_will_be_send_to_this_address": "les e-mails du système seront envoyés à cette adresse, pas à l’adresse de connexion",
		"driver_license": "permis de conduire ",
		"pilot_license": "license pilote",
		"ground_crew": "crew",
		"language": "langue ",
		"confirm_message": "êtes-vous sûr de vouloir supprimer?",
		"confirm": "confirmer",
		"contacts": "contacts ",
		"update": "mettre à jour",
		"delete": "supprimer",
		"save": "enregistrer",
		"add_contact": "ajouter contact",
		"crew": "crew",
		"pilot": "pilot",
		"add_crew": "ajouter crew",
		"national_license": "license national",
		"part_fcl_license": "license PART-FCL",
		"license_group_A": "group A",
		"license_group_B": "group B",
		"license_group_C": "group C",
		"license_group_D": "group D",
		"license_gas": "ballon de gaz",
		"license_number": "numéro de license",
		"license_expiry_date": "date d'expiration OPC",
		"license_extra_crew": "license extra crew",
		"medical_expiry_date": "échéance médical",
		"weight": "poids",
		"warning_not_current": "la validité du pilote expire dans les 30 jours, vérifiez les dates d’expiration",
		"error_not_current": "pilote cloué au sol, vérifiez les dates d’expiration",
		"assigned_to_flight": "assiste le vol",
		"your_flight": "ton vol",
		"your_flights": "tes vols",
		"pilots": "pilotes"
	},
	"BALLOONS": {
		"name": "Nom",
		"call_sign": "immatriculation",
		"capacity": "capacité",
		"total_weight": "poids total",
		"balloons": "ballons",
		"confirm_message": "êtes-vous sûr de vouloir supprimer?",
		"confirm": "confirmer",
		"update": "mettre à jour",
		"delete": "supprimer",
		"save": "enregistrer",
		"add_balloon": "ajouter un ballon",
		"group": "groupe",
		"MLM": "MTM (poids minimum d'atterrissage)",
		"MTOM": "MTOM (poids maximum au décollage)",
		"min_fuel": "consommation par heure (Litre par heure)",
		"fuel": "consommation par heure par 20°C et nombre max de passagers",
		"envelope": "enveloppe",
		"envelope_manufacturer": "constructeur de l'enveloppe",
		"envelope_type": "type d'enveloppe",
		"envelope_volume": "Volume de l'enveloppe",
		"envelope_weight": "poids de l'enveloppe",
		"envelope_hours": "nombre heures total de l'enveloppe",
		"envelope_inspection_date": "inspection valide jusqu'à",
		"balloon_temp": "température de l'enveloppe",
		"burner": "brûleur",
		"burner_manufacturer": "constructeur du brûleur",
		"burner_type": "type de brûleur",
		"burner_weight": "poids du brûleur",
		"cylinder_hint": "Enregistrez d'abord le ballon et ajoutez ensuite les cylindres. Assurez-vous de créer d'abord les cylindres dans le système. Les mêmes cylindres peuvent être utilisés sur plusieurs ballons",
		"cylinder": "bouteilles  de gaz",
		"cylinder_manufacturer": "constructeur",
		"cylinder_volume": "volume",
		"cylinder_count": "nombre",
		"cylinder_empty_weight": "poids à vide",
		"cylinder_full_weight": "poids plein",
		"cylinder_total_weight": "poids total",
		"cylinder_number": "numéro",
		"cylinder_serial": "matricule",
		"basket": "nacelle",
		"basket_manufacturer": "constructeur de la nacelle",
		"basket_type": "type de nacelle",
		"basket_weight": "poids de la nacelle",
		"insurance_company": "assurance",
		"insurance_due_date": "date d'expiration assurance",
		"add_cylinder": "add cylinder",
		"error_not_current": "Ballon échoué, mettre à jour les dates d’expiration",
		"warning_not_current": "ballon expire dans 30 jours, mettez à jour les dates d’expiration"
	},
	"VOUCHERS": {
		"status": "état",
		"create_booking": "create booking",
		"available": "disponible",
		"used": "reservé",
		"expired": "invalide",
		"voucher_type": "type",
		"show_all_types": "montrer tous les types",
		"voucher_reference": "référence",
		"issue_date": "date de création",
		"expiry_date": "date de validité",
		"contactName": "nom",
		"contactPhone": "téléphone",
		"contactLanguage": "langue",
		"contactEmail": "email",
		"contact_city": "ville",
		"recipient_info": "détails de la personne qui recevra et utilisera le bon",
		"buyer_info": "détails de la personne qui a acheté le bon",
		"details_info": "informations spécifiques sur le bon lui-même",
		"buyer": "acheté par",
		"recipient": "destinataire",
		"recipientName": "nom du destinataire",
		"recipientPhone": "téléphone",
		"recipientLanguage": "langue",
		"recipientEmail": "email",
		"recipientAddress": "adresse",
		"recipientPostalCode": "code postal",
		"recipientCity": "ville",
		"recipientCountry": "pays",
		"details": "détails",
		"comments": "commentaires",
		"paymentAmount": "montant",
		"paymentStatus": "état de paiment",
		"paymentDate": "date de paiment",
		"adults": "adultes",
		"children": "enfants",
		"external": "externe",
		"external_source": "source",
		"tickets": "tickets",
		"voucher": "bon de cadeau",
		"available_vouchers": "bons disponible",
		"confirm_message": "êtes-vous sûr de vouloir supprimer ce bon cadeau?",
		"unpaid_vouchers": "Attention! Il y a des bons non payés",
		"IMPORT": {
			"import": "import",
			"upload_csv": "upload CSV",
			"upload_valid_csv_with_this_header": "upload a valid CSV with the following header",
			"example_with_2_adults": "example of a voucher with 2 adults",
			"choose_file": "choose file",
			"check_file": "check file",
			"check_errors": "check errors",
			"not_a_valid_CSV": "this is not a valid CSV",
			"valid_CSV": "no validation errors",
			"click_import": "click import",
			"number_of_vouchers": "number of vouchers",
			"import_status": "import status",
			"imported_succesfully": "successfully imported",
			"imported_with_errors": "errors during import",
			"finish": "finish",
			"row": "row",
			"error_more_less_columns": "more or less columns than expected",
			"error_header": "error in the header",
			"error_contact_name": "error in contactName, this field is mandatory",
			"error_contact_phone": "error in contactPhone, this field is mandatory and in the format M:+32...",
			"error_contact_language": "error in contactLanguage, this field is mandatory",
			"error_voucher_type": "error in voucher type, this field is mandatory",
			"error_voucher_reference": "error in voucher reference, this field is mandatory",
			"error_issue_date": "error in issue date, this field is mandatory and in the format YYYY-MM-DD",
			"error_expiry_date": "error in expiry date, this field is mandatory and in the format YYYY-MM-DD",
			"error_payment_date": "error in payment date, this field is mandatory and in the format YYYY-MM-DD",
			"error_payment_status": "error in payment status, this field is mandatory and accepted values are OPEN or PAID",
			"error_payment_type": "error in payment type, this field is mandatory and accepted values are NOPA, CASH, WIRE, BANC, CRED, ONLI",
			"error_adults": "error in adults, this must be a number",
			"error_children": "error in children, this must be number",
			"error_import": "error during import",
			"succes_import": "imported successfully",
			"cancel": "cancel"
		}
	},
	"BOOKINGS": {
		"id": "no",
		"availability": "disponibilité",
		"PERI": "disponibilité - plusieurs jours",
		"DATE": "disponibilité pour un jour spécifique",
		"MOR": "matin",
		"EVE": "soir",
		"mo": "lu",
		"tu": "ma",
		"we": "me",
		"th": "je",
		"fr": "ve",
		"sa": "sa",
		"su": "di",
		"save": "enregistrer",
		"child": "enfant",
		"name": "nom",
		"weight": "poids",
		"luggage": "bagage",
		"paymentType": "type de paiement ",
		"paymentReference": "référence de paiement ",
		"paymentAmount": "montant de paiement ",
		"paymentStatus": "état de paiement ",
		"paymentDate": "date de paiment",
		"delete": "supprimer",
		"OPEN": "ouvert",
		"UNK": "ajouté",
		"INV": "invité par sms",
		"CON": "confirmé",
		"completed": "effectué",
		"CAN": "annulé",
		"CPP": "passagers commerciaux exclusifs",
		"COP": "passagers commerciaux",
		"NCP": "passagers non-commerciaux",
		"booking_date": "date de réservation",
		"days_expired": "{{ d }} jours expiré",
		"startDate": "date de début",
		"endDate": "date de fin",
		"status": "état",
		"bookingType": "type de réservation",
		"bookingCode": "Référance de la réservation",
		"bookingCodeHint": "cliquez afin d'ouvrir l'application de passagers",
		"bookingCodeMissingEmail": "sans email, les passagers n'ont pas accès à l'application de passagers",
		"commentsTitle": "commentaires",
		"comments": "commentaires internes",
		"pax_comments": "commentaires passager",
		"region": "région",
		"source": "source",
		"new_source": "nouvelle source",
		"you_can_manage_all_sources_in_settings": "vous pouvez gérer toutes les sources dans les paramètres",
		"source_validation": "source est obligatoire, min 3, max 50 caractères et doit être unique",
		"source_list_validation": "choisissez une source dans la liste ou créez d'abord une nouvelle source",
		"source_list_empty": "aucune source trouvée, ajoutez la première source",
		"locationType": "base de décollage",
		"REGI": "toutes les bases de décollages dans une région",
		"FIXE": "bases de décollage spécifique dans une région",
		"PRIV": "lieu de décollage privé",
		"contactPerson": "personne de contact",
		"contactName": "nom de contact ",
		"contactPhone": "téléphone de contact",
		"contactEmail": "email de contact",
		"contactLanguage": "langue de contact",
		"action_needed": "action nécessaire",
		"answer_flight_yes_no": "Répondez VOL OUI ou VOL NON",
		"flight_yes": "VOL OUI",
		"flight_no": "VOL NON",
		"PAID": "payé",
		"REFUNDED": "remboursé",
		"not_paid": "à payer",
		"CASH": "cash",
		"COUP": "bon (dans système)",
		"VOUC": "bon",
		"NOPA": "pas de paiment",
		"WIRE": "virement bancaire",
		"BANC": "bancontact",
		"CRED": "credit card",
		"ONLI": "online",
		"INVO": "facture",
		"only_available_with_paymenttype_voucher": "seulement disponsible avec type de payment bon",
		"bookings": "réservations",
		"booking": "réservation",
		"confirm": "confirmer",
		"update": "mettre à jour",
		"contact": "contact",
		"passengers": "passagers",
		"cancel": "annuler",
		"next": "suivant",
		"flightDate": "date de vol",
		"flightPeriod": "AM/PM",
		"flight_balloon": "ballon",
		"flight_location": "base de décollage",
		"flight_pilot": "pilote",
		"add_passenger": "ajouter passeger",
		"messages": "messages",
		"no_bookings_found": "aucune réservation trouvée",
		"inviteCount": "invité",
		"confirm_message": "êtes-vous sûr de vouloir supprimer cette réservation?",
		"always_available": "toujours disponible dès",
		"limited_availability": "disponibilité limitée dès",
		"end_before": "vol avant",
		"passenger": "passager",
		"details": "détails",
		"payment_details": "paiments",
		"address_details": "adresse",
		"disability": "assistance requis",
		"category": "catégorie",
		"address": "addresse",
		"postalCode": "code postal",
		"city": "ville",
		"country": "pays",
		"signature": "signature",
		"added_flight": "vol planifié",
		"select_flight": "sélectionner vol",
		"no_flight_added": "pas de vol planifié",
		"no_flight_data": "les données s'affichent que pour un endroit de décollage et une date déterminée",
		"available_flights": "vols disponibles",
		"no_available_flights": "pas de vol disponible",
		"available_balloons": "ballons disponibles",
		"no_available_balloons": "pas de ballon disponible",
		"totalAmount": "montant total",
		"paid_amount": "payé",
		"openAmount": "solde à payer",
		"prepayment": "prepaiment",
		"prepaymentDate": "date de prepaiment",
		"passengers_to_pay": "passager(s) à payer",
		"mark_paid": "marque comme payé",
		"remove_from_passengerlist": "retirer de la liste passager",
		"add_to_passengerlist": "ajouter un(e) passager(e)",
		"checkin_passengers": "retour au check in",
		"view_checked_in": "montré les passagers enregistrés(check in)",
		"hide_checked_in": "cacher les passagers enregistrés (check in)",
		"import": "import",
		"upload_csv": "upload CSV",
		"upload_valid_csv_with_this_header": "upload a valid CSV file with the following header",
		"example_in_region_with_id_1_with_2_adults_and_0_children": "example of a booking with 2 adults in region with Id 1",
		"choose_file": "select file",
		"check_file": "check file",
		"check_errors": "correct the following errors",
		"not_a_valid_CSV": "this is not a valid file",
		"valid_CSV": "there are no errors",
		"click_import": "click to import",
		"number_of_bookings": "number of reservations",
		"number_of_objects_upcoming": "réservation/vols futurs",
		"number_of_objects_history": "réservation/vols terminés",
		"import_status": "import status",
		"imported_succesfully": "succesfully imported",
		"imported_with_errors": "errors during import",
		"finish": "finish",
		"row": "row",
		"error_more_less_columns": "more or less columns than expected",
		"error_header": "error in the header",
		"error_contact_name": "error in contactName, this field is mandatory",
		"error_contact_phone": "error in contactPhone, this field is mandatory and in the format M:+32...",
		"error_contact_language": "error in contactLanguage, this field is mandatory",
		"error_region": "error in region, this field is mandatory and must have the ID of the region",
		"error_adults": "error in adults, this must be a number",
		"error_children": "error in children, this must be a number",
		"errorImport": "error during import",
		"succesImport": "imported succesfully",
		"flight_confirmation": "vol confirmation",
		"date_filter_is_yyyy-mm-dd": "filtrer par date = yyyy-mm-dd",
		"select_your_view": "choissir la vue",
		"VIEW": {
			"standard": "vue par défaut",
			"openAmount": "avec solde dû",
			"toConfirm": "à confirmer",
			"bookmarked": "marquées",
			"availableThisYear": "disponibilité cette année",
			"availableNext30Days": "disponibilité les 30 jours suivants",
			"flyingThisYear": "avec vol cette année",
			"flyingNext30Days": "avec vol les 30 jours suivants",
			"withoutFlight": "sans vol"
		},
		"flight_confirmed": "vol confirmé",
		"invited_waiting_answer": "invité, réponse attendu",
		"MERGE": {
			"merge": "fusionner",
			"load": "charger",
			"restart": "redémarrer",
			"select_booking": "selectionner réservations",
			"select_booking_hint": "La réservation à gauche sera supprimée, celle à droite sera enregistrée",
			"merge_booking": "fusionner réservations",
			"merge_booking_hint": "donner votre accord avant de pouvoir enregistrer et supprimer",
			"booking_id_to_delete": "id de la réservation à supprimer",
			"booking_id_to_merge": "id de la réservation à fusionner",
			"error_loading": "erreur lors du chargement, sélectionnez une autre id ou redémarrez",
			"warning_to_switch": "avertissement: vous choisissez de supprimer la réservation la plus récente",
			"save_and_delete": "enregistrer cette réservation et supprimer",
			"confirm_to_delete": "accord pour supprimer",
			"replace": "remplacer",
			"add": "ajouter",
			"undo": "annuler",
			"fields_are_identical": "champs sont identiques",
			"remove": "supprimer"
		},
		"LOG": {
			"logbook": "historique",
			"outbox": "e-mails envoyés",
			"only_logs_from_UI": "Connexion de Quick Release (pas via UP ou via site web)",
			"ADDE": "ajouté  au vol",
			"REMO": "supprimé du vol",
			"CONF": "confirmé pour le vol",
			"cancel": "vol annulé, transféré à la liste des réservations",
			"COMP": "vol effectué, transféré aux listes des réservations exécutées",
			"INVI": "invité par SMS au vol",
			"CREA": "réservation créée dans le système",
			"update": "réservation modifiée (un ou plusieurs attributs)",
			"DELE": "réservation supprimée du système",
			"MAIL": "email envoyé",
			"view_email": "montrer email",
			"flight": "vol"
		},
		"invoice_name": "nom de la facture",
		"vat_number": "numéro de TVA",
		"age": "âge",
		"transport": "transport",
		"age_amount": "ans"
	},
	"COMMUNICATIONS": {
		"sent_at": "envoyé à ",
		"phone": "téléphone",
		"name": "nom",
		"text": "texte",
		"status": "état",
		"message_id": "ID de message",
		"message_price": "prix d'un message",
		"network": "réseau",
		"remaining_balance": "solde restant",
		"received_at": "reçu à",
		"msisdn": "msisdn",
		"message_timestamp": "temps de message",
		"keyword": "mot-clé",
		"pilot_invitation_sms": "SMS aux pilotes",
		"crew_invitation_sms": "SMS aux crews",
		"passenger_invitation_sms": "SMS aus passagers",
		"cancel_sms": "SMS d'annulation",
		"reminder_sms": "SMS rappel",
		"sms_in": "SMS entrant",
		"messages": "ID de message",
		"flight": "vol",
		"booking": "réservation",
		"sms_out": "SMS sortant",
		"to": "destinataire",
		"unanswered_messages": "messages non répondus",
		"mark": "marquer",
		"mark_all": "marquer tous",
		"mark_paid": "marquer payé",
		"mark_not_paid": "marquer pas payé",
		"messages_as_read": "messages lus",
		"you": "tu",
		"mail_copy": "Email PDF",
		"action_succeed": "action réussie",
		"accepted": "accepté",
		"delivered": "livré",
		"failed": "n’a pas réussi à livrer",
		"unknown": "erreur inconnue",
		"retry": "réessayer plus tard",
		"wrongnumber": "mauvais numéro",
		"none": "rien"
	},
	"DASHBOARDS": {
		"flights": "vols",
		"no_flights": "pas de vols",
		"upcoming": "vols futurs",
		"historical": "terminé",
		"this_month": "ce mois",
		"bookings": "réservations",
		"vouchers": "bons de cadeau",
		"open": "ouvert",
		"completed": "effectué",
		"expired": "expiré",
		"idea_voting": "idées",
		"search": "chercher",
		"payments": "paiements",
		"charts": "graphiques",
		"flights_of_today_and_tomorrow_morning": "les vols d'aujourd'hui ou le lendemain matin",
		"flights_of_next_7_days": "les vols des 7 jours suivants",
		"bookings_to_confirm_bookmarked_and_of_today_or_tomorrow_morning": "les réservations à confirmer, réserver pour un vol d'aujourd'hui ou le lendemain matin, les nouvelles réservations ou avec un drapeau dashboard",
		"basic_charts_of_reservations_and_flights": "graphiques des réservations et vols",
		"charts_are_no_longer_shown_on_the_dashboard_please_navigate_to_reports": "graphiques ne sont plus part du dashboard, vous les trouvez dans rapports",
		"navigate_to_reports": "Naviguer vers rapports",
		"most_recent_sms_per_contact": "conversations récentes",
		"show_all_messages": "tous",
		"show_messags_from_this_flight": "seulement ce vol",
		"show_messages_from_this_booking": "seulement cette réservation",
		"show_unread": "non lus"
	},
	"FLIGHTS": {
		"go back": "retourner",
		"save": "enregistrer",
		"unknown": "inconnue",
		"CON": "confirmé",
		"invited_by_sms": "invité par sms",
		"DEC": "refusé",
		"MOR": "matin",
		"EVE": "soir",
		"CPF": "vol commercial exclusif",
		"COF": "vol commercial",
		"NCF": "vol récréatif",
		"PRI": "non partagé",
		"PUB": "partagé",
		"SCH": "planifier",
		"REA": "marquer prêt",
		"MARK": {
			"SCH": "planifie",
			"REA": "marque prêt",
			"CAN": "annule",
			"FIN": "termine"
		},
		"INFO": {
			"SCH": "Rassemblez votre équipement et préparez-vous pour le vol",
			"REA": "Bravo! Ce vol est prêt au décollage",
			"CAN": "Ce vol est annulé, vous pouvez le supprimer du système",
			"FIN": "Ce vol est terminé, excellent travail!"
		},
		"checkin": "check in",
		"CAN": "annuler",
		"FIN": "terminer",
		"balloon": "ballon",
		"date": "date de vol",
		"period": "temps",
		"event": "événement",
		"hour": "heure",
		"sunrise": "sunrise",
		"sunset": "sunset",
		"flight_type": "type de vol",
		"location": "base",
		"visibility": "visibilité web",
		"web": "web",
		"status": "état",
		"comment": "commentaire",
		"pilot": "pilot",
		"pilot_status": "état",
		"action_needed": "action nécessaire",
		"ground_crew": "crew",
		"pilot_and_crew": "pilot et crew",
		"not_enough_data": "pas assez de données",
		"answer_pilot_yes_no": "Répondez PILOT OUI ou PILOT NON",
		"pilot_yes": "PILOT OUI",
		"pilot_no": "PILOT NON",
		"Flight date cannot be set in the past": "la date de vol ne peut pas être dans le passé",
		"answer_crew_yes_no": "Répondez CREW OUI ou CREW NON",
		"crew_yes": "CREW OUI",
		"crew_no": "CREW NON",
		"flights": "vols",
		"flight_bookings": "réservations",
		"UNK": "ajouté",
		"INV": "invité par sms",
		"invite_all": "SMS inviter tous",
		"inviteCount": "invitations",
		"invite_count_1": "invitation",
		"mo": "lu",
		"tu": "ma",
		"we": "me",
		"th": "je",
		"fr": "ve",
		"sa": "sa",
		"su": "di",
		"invite": "envoi sms",
		"mail": "email",
		"reply": "répondez ici",
		"confirm": "confirmer",
		"remove": "supprimer",
		"contactName": "nom de contact",
		"bookingType": "type de réservation",
		"region": "région",
		"passengers": "passagers",
		"no_bookings_selected": "aucune réservation sélectionnée",
		"matching_bookings": "réservations",
		"mismatching_bookings": "réservations sur un autre vol",
		"mismatchReasons": "correspondance des vols",
		"add_all": "ajouter tous",
		"add": "ajouter",
		"already_added": "déjà ajouté",
		"messages": "messages",
		"calendar": "calendrier",
		"download_manifest": "télécharger le manifeste",
		"send_sms_reminder": "SMS de rappel",
		"update": "mettre à jour",
		"are_you_sure": "êtes-vous sûr?",
		"reminder_sms_will_be_sent": "SMS rappel",
		"edit_sms": "changer SMS",
		"preview_sms": "exemple",
		"send": "envoyez",
		"send_to_pax": "SMS aux PAX",
		"send_to_pax_and_crew": "SMS aux PAX et CREW",
		"send_up_url_with_sms": "envoyez UP url avec SMS",
		"sent": "envoyé",
		"message_has_been_sent": "le message a été envoyé",
		"message_has_not_been_sent": "le message n'a pas été envoyé",
		"not_sent": "pas assez de données",
		"not_confirmed_bookings_will_be_removed_from_flight": "les réservations suivantes non confirmées seront supprimées du vol",
		"flight_will_be_cancelled": "le vol sera annulé",
		"flight_will_be_finished": "le vol sera cloturé",
		"flight_will_be_reopened": "le vol sera remis au planning",
		"yes_cancel_it": "oui annuler",
		"no_please": "non merci",
		"cancel_sms_will_be_sent": "SMS d'annulation va être envoyé",
		"not_cancelled": "pas annulé",
		"flight_has_not_been_cancelled": "le vol n'a pas été annulé",
		"confirm_message": "êtes-vous sûr de vouloir supprimer?",
		"flight_pilot_in_command": "commandant de bord",
		"invite_by_sms": "sms",
		"unsupported_phonenumber": "numéro de téléphone non supporté",
		"sms_unclear": "sms douteux",
		"no_pilot_in_command_selected": "aucun pilote sélectionné",
		"flight_crew": "crew",
		"no_crew_selected": "aucun crew séléctionne",
		"available_pilots_and_crew": "pilotes et crew disponible",
		"select_pilot": "choissisez pilote",
		"select_crew": "choissisez crew",
		"other_flight": "autre vol",
		"add_as_crew": "ajouter crew",
		"add_as_pilot": "ajouter pilote",
		"warning": "attention",
		"creating_a_flight_can_lead_to_costs": "la création d'un vol peut générer des coûts",
		"proceed": "continuer",
		"cancel": "annuler",
		"passenger_count": "passagers",
		"crew_count": "crew",
		"type": "type",
		"no_pilot": "pas de pilot ",
		"seats": "passagers",
		"delete": "supprimer",
		"set_ready": "prêt",
		"address": "addresse",
		"postalCode": "code postal",
		"city": "ville",
		"country": "pays",
		"manifest": "télécharger le manifeste",
		"certificates": "certificats",
		"takeoffTime": "heure de décollage",
		"landingLocation": "lieu d'atterrissage",
		"landingDetails": "détails d'atterisage",
		"total_weight": "poids total",
		"landingTime": "heure d'atterrissage",
		"flightTime": "temps de vol",
		"and": "et",
		"name": "nom",
		"ref": "réf",
		"amount": "montant",
		"weight": "poids",
		"signature": "signature",
		"reminder_sms": "SMS rappel",
		"also_to_pilot_and_crew": "adresser aussi aux crew et pilotes",
		"cancel_sms": "SMS d'annulation",
		"no_flights_found": "aucun vol trouvé",
		"no_location": "pas de base de décollage",
		"show": "montrer",
		"hide": "cacher",
		"lift": "lift",
		"lift_needed": "lift nécessaire",
		"lift_available": "lift disponible",
		"lift_spare": "lift de réserve",
		"takeoff_location": "Takeoff Location",
		"temp": "temperature",
		"takeoff_height": "altitude de décollage",
		"maximum_height": "altitude max",
		"temp_takeoff": "température ext décollage",
		"temp_max": "température max dans l'enveloppe",
		"weather_qfe": "Pression au décollage (QNH)",
		"weather_pmax": "Pression maximume",
		"balloon_volume": "volume de l'enveloppe",
		"balloon_temp": "température de l'enveloppe",
		"create_add": "créer vol",
		"weather": "météo",
		"copy_weather_to": "copier météo vers",
		"use_meteoblue": "utiliser meteoblue",
		"skeyes_ballooning_bulletin": "skeyes ballooning bulletin",
		"use_skeyes": "utiliser Skeyes",
		"weather_forecast": "prévisions",
		"no_weather_forecast": "pas de prévisions disponible",
		"windSummary": "résumé du vent",
		"weather_source": "source",
		"weather_forecast_date": "date de prévisions",
		"vfr_visual": "visibilité",
		"vfr_clouds": "nuages",
		"wind_gl": "vent au sol",
		"wind_gl_short": "wind GL",
		"wind_dir": "direction",
		"wind_gl_direction": "direction de vent ground level",
		"wind_10": "vent 1000FT",
		"wind_10_direction": "direction de vent 1000FT",
		"wind_20": "vent 2000FT",
		"wind_20_direction": "direction de vent 2000FT",
		"wind": "vent",
		"clear": "clear",
		"fuel_spare": "gaz de réserve",
		"planned_flight_time": "temps de vol planifié",
		"spare_flight_time": "temps de vol de réserve",
		"maximum_flight_time": "temps de vol disponible",
		"select_balloon": "sélectionnez le ballon",
		"select_location": "sélectionnez la base",
		"offsets": "changer offsets sunrise/sunset",
		"flight_hour_will_be_calculated_based_on_sunset_minus_the_offset_minutes": "le temps du vol est calculé sur base de sunset (ou sunrise) moins l'offset en minutes,arrondi à une demi-heure. Des valuers négatives sont permits (au cas de sunrise par exemple).",
		"offset_sunrise": "offset sunrise (minutes)",
		"offset_sunset": "offset sunset (minutes)",
		"payment_overview": "payements",
		"go_back": "retour",
		"traject": {
			"trajectory": "trajet",
			"custom_flight_profile": "profil de vol personnalisé",
			"duration": "durée",
			"duration_is_required": "durée est obligatoire",
			"level": "niveau",
			"level_is_required": "niveau est obligatoire",
			"save": "enregistrer",
			"no_trajectory_without_meteo": "pas de trajet sans météo",
			"update_custom_profile": "mettre à jour profil personnalisé",
			"refresh_weather": "rafraîchir la météo",
			"talking_to_meteoblue": "parler à meteoblue",
			"refresh_hint": "meteoblue met à jour leur modèle météorologique toutes les 12 heures. Des mises à jour plus rapides sont inutiles et sont désactivées."
		},
		"LANDING": {
			"landing": "atterrissage",
			"landing_details": "détails",
			"map": "map",
			"no_details": "pas de détails remplis",
			"landing_data": "Les données d’atterrissage ne peuvent être modifiées que dans l’écran d’enregistrement mobile pour l’instant",
			"refuelVolume": "volume de gaz ravitaillé"
		},
		"FILTER": {
			"date_range_filter": "filtre de plage de dates",
			"from": "de",
			"till": "jusqu'au",
			"excluded": "exclu",
			"no_date_filter_active": "pas de filtre de date actif",
			"enter_a_date_range": "entrez une plage de dates",
			"start_date": "date de début",
			"end_date": "date de fin",
			"month": "mois",
			"week": "semaine",
			"day": "jour",
			"flights": "vols"
		},

		"flight_no_bookings": "ce vol n'a pas encore de réservation",
		"mor_flight": "vol du matin",
		"eve_flight": "vol du soir",
		"mor_flights": "vols du matin",
		"eve_flights": "vols du soir",
		"no_mor_flights": "pas de vols du matin",
		"no_eve_flights": "pas de vols du soir",
		"responded_pilots": "les pilotes ont répondu",
		"delete_flight": "supprimer le vol",
		"toggle_visibility": "modifier la visibilité",
		"tags": "drapeaux",
		"COPY": {
			"copy_flight": "copier le vol",
			"select_multiple_dates": "sélectionnez plusieurs dates pour copier le vol avec le même lieu, ballon, pilote et équipage",
			"selected_dates": "dates sélectionnées",
			"no_tags": "aucun drapeau",
			"copy": "copier"
		},
		"PILOTANDCREW": {
			"selected": "sélectionné",
			"most_used": "le plus utilisé",
			"this_is_the_most_used_pilot_and_crew": "c'est le pilote et crew les plus utilisés pour ce ballon",
			"accept": "accepter",
			"all_pilots_and_crew": "tous les pilotes et crew",
			"select": "sélectionner"
		}
	},
	"MANIFEST": {
		"fuel_consumption": "fuel consumption",
		"fuel_needed": "fuel required",
		"fuel_available": "fuel available",
		"load_chart_and_passenger_list": "Load Calculations and Passenger List",
		"passenger_list": "Passenger List",
		"weather_conditions": "Weather Conditions",
		"fuel_calculations": "Fuel Calculations",
		"load_chart": "Load Calculations",
		"after_flight": "After Flight",
		"visible_defects": "Visible Defects",
		"yes_no": "Yes/No",
		"date": "date",
		"signature": "signature",
		"defect": "defect",
		"action": "action",
		"certificate": "certificate",
		"authority": "authority",
		"initials": "initials",
		"confirmation": "this confims that the specified actions were executed according PART-ML and that the aircraft is declared as ready for the next flight."
	},
	"LOCATIONS": {
		"name": "nom",
		"description": "description",
		"region": "région",
		"address": "addresse",
		"postalCode": "code postal",
		"city": "ville",
		"country": "pays",
		"height": "hauteur",
		"maximum height": "altitude maximum",
		"locations": "bases de décollage",
		"confirm_message": "êtes-vous sûr de vouloir supprimer?",
		"confirm": "confirmer",
		"update": "mettre à jour",
		"delete": "supprimer",
		"regions": "régions",
		"save": "enregistrer",
		"add_location": "ajouter base de décollage",
		"add_region": "ajouter région",
		"hot_airfield": "hot airfield",
		"coordinates": "coordonnées géo",
		"geolocate": "géolocaliser",
		"no_results": "Impossible de géolocaliser",
		"contact": "contact",
		"takeoff_location": "base de décollage",
		"takeoff_location_subtitle": "Les coordonnées de la base de décollage sont utilisées pour la prévision météo, le calcul de la trajectoire et de la portance.",
		"meetingpoint": "point de rencontre",
		"meetingpoint_subtitle": "L'adresse du point de rencontre est communiquée aux passagers (UP).",
		"same_as_takeoff": "même que base de décollage",
		"meetingpointAddress": "addresse du point de rencontre",
		"CONTACT": {
			"name": "nom",
			"type": "type",
			"comment": "commentaire",
			"phone": "téléphone",
			"email": "email",
			"when_to_contact": "quand contacter",
			"how_to_contact": "comment contacter",
			"NOMSG": "personne ne sera contacté",
			"MAIL": "email",
			"SMS": "sms",
			"mail": "pendant la préparation du vol, la personne de contact recevra un email",
			"sms": "pendant la préparation du vol, la personne de contact recevra un sms",
			"no_message": "la personne de contact ne recevra pas de message pendant la préparation du vol",
			"add_sms_template": "ajouter template sms",
			"add_mail_template": "ajouter template email",
			"mail_template": "email template",
			"sms_template": "sms template",
			"warning_email": "vous n'avez pas encore envoyé d'email à la personne de contact",
			"warning_sms": "vous n'avez pas encore envoyé de sms à la personne de contact"
		}
	},
	"FORECAST": {
		"no_mobile": "Météo n'est pas disponible sur mobile",
		"select_favorite": "Sélectionnez un base comme favori pour afficher les prévisions météo",
		"no_forecast": "Pas de météo disponible pour cet endroit. Vérifier la latitude et la longitude",
		"remove_favorite": "supprimer comme favori",
		"mark_favorite": "marquer favori",
		"favorite_description": "ce base est votre base préféré. Les prévisions météo de meteoblue sont disponibles sur le tableau de bord pour ce base.",
		"non_favorite_description": "ce base n’est pas votre base préféré. Marquez que c’est votre base préféré pour afficher les prévisions météo de meteoblue sur le tableau de bord",
		"meteo": "météo",
		"time": "temps",
		"summary": "résumé"
	},
	"REGIONS": {
		"name": "nom",
		"locations": "bases",
		"no_locations_yet": "pas de bases",
		"name_required": "nom obligatoire",
		"change_color": "cliquez ici pour changer la couleur de la région",
		"reset_color": "réinitialiser la couleur",
		"confirm_color": "confirmer la couleur",
		"cancel_color": "annuler la couleur"
	},
	"EVENTS": {
		"new_event": "nouvel événement",
		"information": "ajoute un événement sur un date spéficique ou ajouter les jours fériés",
		"new_crew_event": "nouvel rendez-vous",
		"crew_information": "ajoute un jour ou vous n'êtes pas disponible (pilot ou crew)",
		"holidays": "holidays",
		"load_public_holidays_of_year_from_country": "ajouter les jours fériés de {0} de {1}",
		"date": "date",
		"description": "description",
		"save": "enregistrer",
		"cancel": "annuler",
		"required": "obligatoire",
		"crew": "crew",
		"NO": "pas disponible",
		"YES": "disponible",
		"MAYBE": "peut-être disponible",
		"UNK": "disponibilité inconnue"
	},
	"SETTINGS": {
		"user_default_language": "langue par défault",
		"submit": "enregistrer",
		"preferences_language": "langue",
		"preferences_sms": "Préférences SMS",
		"smsTitle": "titre",
		"smsTemplate": "template",
		"pilotInvitationSms": "SMS pilote",
		"example": "exemple",
		"update": "mettre à jour",
		"crewInvitationSms": "SMS crew",
		"passengerInvitationSms": "SMS passagers",
		"cancelSms": "SMS d'annulation",
		"reminderSms": "SMS rappel",
		"locationContactSms": "SMS contact base",
		"emailTitle": "titre",
		"no_emailTitle": "pas de titre",
		"emailType": "type",
		"emailTo": "email à",
		"emailSubject": "sujet",
		"emailBody": "message",
		"emailAttachments": "annexes",
		"emailTest": "email test",
		"mailFromEmail": "E-mail envoyé avec l'adresse",
		"mailFromName": "E-mail envoyé avec le nom",
		"mailReplyToEmail": "Réponse sur l'adresse",
		"mailAccountantName": "nom de l’e-mail du comptable",
		"mailAccountantEmail": "adresse e-mail du comptable",
		"bookingConfirmEmail": "Email confirmation de la réservation",
		"bookingFlightCancelEmail": "Email fin de vol",
		"bookingFlightConfirmEmail": "Email passagers d'un vol",
		"crewFlightEmail": "Email détails de vol aux crews",
		"crewOtpEmail": "Email code pour crews",
		"pilotFlightEmail": "Email détails de vol pour pilots",
		"flight_email": "Email détails de vol",
		"locationContactEmail": "Email contact base",
		"flight_email_passengers": "Email passagers",
		"flight_sms_passengers": "SMS passagers",
		"load_default": "chargez un text standard",
		"passenger_categories": "catégories de passager",
		"category_name": "nom",
		"category_description": "description",
		"category_child": "enfant",
		"category_price": "prix",
		"category_default": "par défaut",
		"delete": "supprimer",
		"mobile_questions": "questions aux passagers (mobile)",
		"question": "question",
		"kind": "enfant",
		"active": "actif",
		"title": "titre",
		"description": "description",
		"language": "langue",
		"texts": "textes",
		"name": "nom",
		"address": "addresse",
		"email": "email",
		"weight": "poids",
		"luggage": "bagage",
		"child": "enfant",
		"accompany": "adulte accompagnant",
		"pay": "payer",
		"signature": "signature",
		"optional": "optionnelle",
		"mandatory": "obligatoire",
		"order": "séquence",
		"titles": "questions",
		"descriptions": "decriptions",
		"yes": "oui",
		"no": "non",
		"nl": "néerlandais",
		"fr": "français",
		"en": "anglais",
		"de": "allemand",
		"id": "id",
		"download_sample": "télécharger exemple",
		"drag_and_drop_file_here": "déposer le fichier ici",
		"browse_for_file": "télécharger un fichier",
		"operator_settings": "préférences generales",
		"operator_backup_and_sync": "backup en sync",
		"operator_bookings": "préférences réservation",
		"operator_flights": "préférences vol",
		"operator_crew": "préférences crew",
		"operator_myflight": "préférences UP (Passengers App)",
		"export": "export",
		"you_can_activate_a_daily_backup": "vous pouvez envoyer un export journalier en HTML à une addresse email. Dès que vous activez le export, vous recevrez immédiatement un export par email, ensuite chaque jour à 4 heures.",
		"daily_backup": "daily export",
		"backup_email": "email",
		"only_update_when_inactive": "vous ne pouvez pas changer l'adresse si le export est active",
		"exported_files": "fichiers exportés",
		"bookings": "toutes les réservations ouvertes",
		"flights": "tous les vols planifiés",
		"vouchers": "tous les bons cadeau",
		"flights_bookings_passengers": "tous les vols planifiés avec leur réservations et leurs passagers",
		"calendarUrl": "URL ICS pour le calendrier",
		"you_can_subscribe_to_all_flights_in_your_personal_calendar": "vous pouvez vous abonner à tous les vols dans votre calendrier personnel",
		"geticsurl": "générer l'URL ICS",
		"readthedocs": "lire la documentation",
		"crew": {
			"title": "Crew",
			"shareBookings": "crew peut voir le détail des réservations sur un vol dans le tableau de bord",
			"shareBookingsHint": "si cela n’est pas coché, crew ne peut voir que le nombre de passagers sur un vol dans le tableau de bord",
			"allowFlightSelection": "crew peut sélectionner des vols dans le calendrier",
			"allowFlightSelectionHint": "si cette option n’est pas cochée, crew ne peut voir que les vols dans le calendrier, mais ne peut pas les sélectionner"
		},
		"booking": {
			"title": "Réservations",
			"childAge": "âge des enfants",
			"childAgeHint": "Chaque passager de cet âge ou moins sera automatiquement désigné comme enfant.",
			"disabledPaymentTypes": "types de paiement désactivés",
			"disabledPaymentTypesHint": "si vous désactivez un type de paiement, il ne sera pas affiché dans l'application de réservation.",
			"none_of_the_paymentTypes_are_disabled": "aucun type de paiement n'est désactivé",
			"disabledPaymentTypesSave": "enregistrer les types de paiement désactivés",
			"sources": "sources"
		},
		"flight": {
			"title": "vols",
			"sunriseOffset": "décalage de lever du soleil",
			"sunriseOffsetHint": "l'heure du vol est calculée sur la base du lever du soleil moins le nombre de minutes de décalage, arrondi à la demi-heure inférieure.",
			"sunsetOffset": "décalage de coucher du soleil",
			"sunsetOffsetHint": "l'heure du vol est calculée sur la base du coucher du soleil moins le nombre de minutes de décalage, arrondi à la demi-heure inférieure.",
			"meetingTimeOffset": "décalage de l'heure de rendez-vous (cette fonctionnalité n'est pas encore disponible)",
			"meetingTimeOffsetHint": "l'heure du rendez-vous pour le vol est calculée sur la base de l'heure du vol moins le nombre de minutes de décalage. Si 0, l'heure du rendez-vous n'est pas affichée.",
			"sunrise": "lever du soleil",
			"sunset": "coucher du soleil",
			"hourMeeting": "heure de rendez-vous",
			"hour": "heure de vol",
			"save": "enregistrer les décalages"
		},
		"myflight": {
			"title": "Quick Release UP (Passengers App)",
			"autoConfirm": "confirmer automatiquement le vol lorsqu’il est demandé via UP",
			"autoConfirmHint": "si cela n’est pas coché, l’opérateur doit confirmer le vol manuellement, lorsqu’il est ajouté via UP",
			"endOfSeason": "fin de saison",
			"endOfSeasonHint": "si cette option est cochée, les vols ne peuvent plus être réservés via UP dès fin de saison",
			"flights": "quels vols sont visibles dans UP",
			"ALL": "tous les vols (tous les vols planifiés)",
			"REGIONAL": "vols régionaux (tous les vols planifiés dans la région de la réservation)",
			"NONE": "aucun vol (les passagers ne peuvent pas réserver via UP)",
			"parameters": "paramètres",
			"branding": "branding",
			"logo": "logo",
			"logoHint": "téléchargez un logo qui sera affiché dans l'application UP",
			"drag_and_drop_file_here": "glissez et déposez votre fichier .jpg ou .png ici (max 500kB)",
			"colors": "couleurs",
			"colorsHint": "cliquez sur chaque couleur pour les adapter à votre propre style. Vous pouvez choisir une couleur dans la palette de votre logo dans la pop-up",
			"example": "exemple",
			"reset": "réinitialiser couleurs",
			"save": "enregistrer couleurs et logo",
			"primary_background_color": "couleur d'arrière-plan de l'application",
			"secondary_background_color": "couleur d'arrière-plan des tuiles",
			"primary_foreground_color": "couleur du texte",
			"secondary_foreground_color": "couleur du texte d'information",
			"invalid_file_type_or_size": "type de fichier invalide ou fichier trop grand"
		},
		"sms": {
			"footerEnabled": "ajouter un pied de page aux SMS",
			"footerEnabledHint": "si cette option n’est pas cochée, le pied de page ne sera pas ajouté à tous les SMS d’invitation"
		}
	},
	"REPORTS": {
		"flights": "vols",
		"bookings_open": "réservations ouvertes",
		"bookings": "réservations nouvelles",
		"vouchers": "bons cadeau",
		"payments": "paiements",
		"sms": "SMS"
	},
	"MAIL": {
		"hide": "cacher détails",
		"show": "montrer détails",
		"sent": "envoyé"
	},
	"SUBSCRIPTION": {
		"sms_available": "compris dans l'abonnement",
		"sms_sent": "SMS envoyés",
		"pct_sms_used": "% de SMS sont utilisés",
		"sms_info": "Prenez contact avec nous dès que 95% des SMS sont utilisés",
		"sms_warning": "Vous avez utilisé 95% ou plus de vos SMS disponible.",
		"order_sms": "Commandez maintenant de SMS en plus",
		"sms_ignore_warning": "Masquer la notification sur l’utilisation des SMS",
		"pct_plan_used": "% de vos sièges sont utilisés",
		"plan_seats_used": "capacité totale des ballons",
		"plan_valid_until": "valable jusqu’au",
		"plan_info": "Contactez-nous pour passer à un forfait supérieur si le nombre de sièges est trop faible ou pour renouveler votre abonnement actuel"
	},
	"TAGS": {
		"delete": "supprimer l'drapeau",
		"enabled": "activé",
		"enable_tag": "activer l'drapeau",
		"disabled": "désactivé",
		"disable_tag": "désactiver l'drapeau",
		"save": "enregistrer l'drapeau",
		"new_tag": "créer une nouvelle drapeau",
		"tag_name": "nom du drapeau",
		"tag_type": "type du drapeau",
		"select_tag_color": "choisissez la couleur",
		"color": "couleur",
		"tag_already_added": "drapeau déjà ajouté",
		"edit": "modifier l'drapeau",
		"flight_tags": "drapeaux de vol",
		"tags": "drapeaux",
		"show_on_dashboard": "montrer sur le dashboard"
	}
}
