<mat-progress-bar *ngIf="isLoadingBookings" mode="indeterminate"></mat-progress-bar>
<div class="table-container" [ngStyle]="tableHeight ? { 'height.px': tableHeight } : {}" #scrollContainer (scroll)="scrollHandler($event)">
	<mat-table
		#table
		[dataSource]="datasourceBookings"
		matSort
		[matSortActive]="sortActive"
		[matSortDirection]="sortDirection"
		(matSortChange)="handleSortEvent($event)"
	>
		<!-- Checkbox Column -->
		<ng-container matColumnDef="status" (click)="$event.stopPropagation()">
			<mat-header-cell [ngStyle]="getCellStyles(120, 120, 120)" *matHeaderCellDef>
				{{ 'BOOKINGS.status' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell [ngStyle]="getCellStyles(120, 120, 120)" *matCellDef="let booking">
				<mat-icon
					[ngClass]="booking?.daysExpired ? 'mat-error-icon' : booking.iconColor === 'accent' ? 'mat-accent-icon' : ''"
					[matTooltip]="booking?.daysExpired ? ('BOOKINGS.days_expired' | translate: { d: booking?.daysExpired }) : ''"
				>
					{{ booking.icon }}
				</mat-icon>
				<span *ngIf="booking?.passengerCounters?.openAmount !== 0" style="color: red; font-weight: bold">{{ currency }}</span>
				<div style="display: flex; flex-direction: column; align-items: center">
					<mat-icon style="display: flex" *ngIf="booking?.passengerCounters?.childCount > 0">escalator_warning</mat-icon>
					<span style="display: flex" *ngIf="booking?.passengerCounters?.childCount > 1">
						{{ booking?.passengerCounters?.childCount }}
					</span>
				</div>
				<div style="display: flex; flex-direction: column; align-items: center">
					<mat-icon *ngIf="booking.passengerCounters?.transportCount > 0">airport_shuttle</mat-icon>
					<span *ngIf="booking.passengerCounters?.transportCount > 1">{{ booking.passengerCounters?.transportCount }} </span>
				</div>
				<div style="display: flex; flex-direction: column; align-items: center">
					<mat-icon *ngIf="booking.passengerCounters?.disabilityCount > 0">accessible</mat-icon>
					<span *ngIf="booking.passengerCounters?.disabilityCount > 1">{{ booking.passengerCounters?.disabilityCount }} </span>
				</div>
				<mat-icon *ngIf="booking?.external">import_export</mat-icon>
			</mat-cell>
		</ng-container>
		<!-- bookingDate -->
		<ng-container matColumnDef="bookingDate">
			<mat-header-cell *matHeaderCellDef mat-sort-header [ngStyle]="getCellStyles(120, 120, 120)"
				>{{ 'BOOKINGS.booking_date' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell *matCellDef="let booking" [ngStyle]="getCellStyles(120, 120, 120)">
				{{ booking?.bookingDate | formatDate: 'DD MMM YY' }}
			</mat-cell>
		</ng-container>
		<!-- name -->
		<ng-container matColumnDef="contactName">
			<mat-header-cell [ngStyle]="getCellStyles(120, 120, 120)" *matHeaderCellDef mat-sort-header
				>{{ 'BOOKINGS.name' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell [ngStyle]="getCellStyles(120, 120, 120)" *matCellDef="let booking">
				{{ booking.contactName }}
			</mat-cell>
		</ng-container>
		<!-- phone -->
		<ng-container matColumnDef="phone">
			<mat-header-cell [ngStyle]="getCellStyles(140, 140, 140)" *matHeaderCellDef>
				{{ 'ACTORS.phone' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell [ngStyle]="getCellStyles(140, 140, 140)" *matCellDef="let booking">
				{{ booking.contactPhone | intphone }}
			</mat-cell>
		</ng-container>
		<!-- comments -->
		<ng-container matColumnDef="comments">
			<mat-header-cell [ngStyle]="getCellStyles(110, 110, 320)" *matHeaderCellDef>
				{{ 'BOOKINGS.commentsTitle' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell
				class="hover-cell"
				[ngStyle]="getCellStyles(110, 110, 320)"
				*matCellDef="let booking"
				[satPopoverAnchor]="p"
				(click)="p.toggle(); $event.stopPropagation()"
			>
				<div style="display: flex; flex-direction: column; justify-content: space-between">
					<div *ngIf="booking.tags && booking.tags.length > 0" style="padding: 3px 0">
						<div style="display: flex">
							@for (tag of booking.tags; track tag) {
							<mat-icon
								(click)="p.toggle(); $event.stopPropagation()"
								[matTooltip]="tag.tag"
								[ngStyle]="tag.backgroundColor ? { color: tag.backgroundColor } : {}"
								>bookmark</mat-icon
							>
							}
						</div>
					</div>
					<sat-popover #p hasBackdrop xAlign="start" yAlign="start" (closed)="updateComments(booking, $event)">
						<div class="sat">
							<app-booking-comments [booking]="booking"></app-booking-comments>
						</div>
					</sat-popover>
					{{ booking.comments }}
					@for (pax of booking.passengers; track $index) { @if (pax.comments) {
					<span>[{{ pax.name }}] {{ pax.comments }}</span>
					} }
				</div>
				<mat-icon class="hover-icon"><span class="material-symbols-outlined"> left_click </span></mat-icon>
			</mat-cell>
		</ng-container>
		<!-- contactCard -->
		<ng-container matColumnDef="contactCard">
			<mat-header-cell [ngStyle]="getCellStyles(85, 120, 120)" *matHeaderCellDef>
				{{ 'BOOKINGS.booking' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell [ngStyle]="getCellStyles(85, 120, 120)" *matCellDef="let booking">
				<div style="display: flex; flex-direction: column">
					<strong>{{ booking.contactName }} </strong>
					<div>{{ booking.contactPhone }}</div>
				</div>
			</mat-cell>
		</ng-container>
		<!-- region -->
		<ng-container matColumnDef="region">
			<mat-header-cell [ngStyle]="getCellStyles(85, 105, 200)" *matHeaderCellDef>
				{{ 'BOOKINGS.region' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell [ngStyle]="getCellStyles(85, 105, 200)" *matCellDef="let booking">
				<div *ngIf="booking?.locationType === 'REGI'">
					<app-region-badge [region]="booking?.region"></app-region-badge>
				</div>
				<div *ngIf="booking?.locationType === 'FIXE'" style="display: flex; flex-direction: column">
					<app-region-badge [region]="booking?.region"></app-region-badge>
					{{ booking?.location?.name }}
				</div>
				<div *ngIf="booking?.locationType === 'PRIV'" style="display: flex; flex-direction: column">
					{{ 'BOOKINGS.' + booking?.locationType | translate | capitalize }}
					{{ booking?.location?.city }}
				</div>
			</mat-cell>
		</ng-container>
		<!-- city -->
		<ng-container matColumnDef="city">
			<mat-header-cell [ngStyle]="getCellStyles(85, 100, 100)" *matHeaderCellDef
				>{{ 'FLIGHTS.city' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell [ngStyle]="getCellStyles(85, 100, 100)" *matCellDef="let booking">
				{{ booking.city }}
			</mat-cell>
		</ng-container>
		<!-- passengers -->
		<ng-container matColumnDef="passengers">
			<mat-header-cell [ngStyle]="getCellStyles(80, 80, 100)" *matHeaderCellDef>
				{{ 'BOOKINGS.passengers' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell [ngStyle]="getCellStyles(80, 80, 100)" *matCellDef="let booking">
				{{ booking?.passengerCounters?.passengerCount + ' (' + booking?.passengerCounters?.totalWeight + ' kg)' }}
			</mat-cell>
		</ng-container>
		<!-- passengersCard -->
		<ng-container matColumnDef="passengersCard">
			<mat-header-cell *matHeaderCellDef>
				{{ 'BOOKINGS.passengers' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell *matCellDef="let booking">
				<div style="display: flex; flex-direction: column">
					<div *ngIf="booking?.inviteCount > 1">
						{{ booking?.passengerCounters?.passengerCount }} ({{ booking?.passengerCounters?.totalWeight }} kg) - {{ booking?.inviteCount }}
						{{ 'FLIGHTS.inviteCount' | translate }}
					</div>
					<div *ngIf="booking?.inviteCount === 1">
						{{ booking?.passengerCounters?.passengerCount }} ({{ booking?.passengerCounters?.totalWeight }} kg) - {{ booking?.inviteCount }}
						{{ 'FLIGHTS.invite_count_1' | translate }}
					</div>
					<div *ngIf="booking?.inviteCount === 0">
						{{ booking?.passengerCounters?.passengerCount }} ({{ booking?.passengerCounters?.totalWeight }} kg)
					</div>
					<div>{{ booking?.city }}</div>
				</div>
			</mat-cell>
		</ng-container>
		<!-- preferredDate -->
		<ng-container matColumnDef="preferredDate">
			<mat-header-cell [ngStyle]="getCellStyles(80, 135, 160)" *matHeaderCellDef mat-sort-header>
				{{ 'BOOKINGS.availability' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell [ngStyle]="getCellStyles(80, 135, 160)" *matCellDef="let booking" [satPopoverAnchor]="p">
				<div class="hover-cell" *ngIf="booking.availabilityType === 'DATE'" (click)="p.toggle(); $event.stopPropagation()">
					@for (preferredFlight of booking.preferredFlights; track preferredFlight) {
					<div style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center; gap: 5px">
						<div>{{ preferredFlight.date | formatDate: 'dd DD MMM YY' }}</div>
						<div *ngIf="preferredFlight.period === 'MOR'">(AM)</div>
					</div>
					}
					<mat-icon class="hover-icon"><span class="material-symbols-outlined"> left_click </span></mat-icon>
				</div>
				<sat-popover #p hasBackdrop xAlign="start" yAlign="start" (closed)="updatePreferredFlights(booking, $event)">
					<div class="sat">
						<app-booking-preferred-date [booking]="booking"></app-booking-preferred-date>
					</div>
				</sat-popover>
			</mat-cell>
		</ng-container>
		<!-- flightdate -->
		<ng-container matColumnDef="flightDate">
			<mat-header-cell [ngStyle]="getCellStyles(200, 200, 200)" *matHeaderCellDef>
				{{ 'BOOKINGS.flightDate' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell [ngStyle]="getCellStyles(200, 200, 200)" *matCellDef="let booking" (click)="$event.stopPropagation()">
				<button
					style="min-width: 170px; padding: 0px"
					*ngIf="booking.flight"
					mat-stroked-button
					(click)="navigateToFlight(booking)"
					[matTooltip]="booking.flight?.passengersCount + '/' + booking.flight.balloon?.capacity"
				>
					<div style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center; gap: 20px">
						<div>{{ booking.flight?.date | formatDate: 'dd DD MMM YY' }}</div>
						<div *ngIf="booking.flight.period === 'MOR'">(AM)</div>
					</div>
					<mat-progress-bar
						*ngIf="booking.flight.passengersOccupancy > 75 && booking.flight.passengersOccupancy <= 100"
						type="determinate"
						color="accent"
						[value]="booking.flight.passengersOccupancy"
					></mat-progress-bar>
					<mat-progress-bar
						*ngIf="booking.flight.passengersOccupancy <= 75"
						type="determinate"
						[value]="booking.flight.passengersOccupancy"
					></mat-progress-bar>
					<mat-progress-bar
						*ngIf="booking.flight.passengersOccupancy > 100"
						type="determinate"
						color="warn"
						[value]="booking.flight.passengersOccupancy"
					>
					</mat-progress-bar>
				</button>
			</mat-cell>
		</ng-container>
		<!-- flight period -->
		<ng-container matColumnDef="flightPeriod">
			<mat-header-cell *matHeaderCellDef>
				{{ 'BOOKINGS.flightPeriod' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell *matCellDef="let booking">
				{{ booking.flightPeriod }}
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="flight_balloon">
			<mat-header-cell *matHeaderCellDef>
				{{ 'BOOKINGS.flight_balloon' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell *matCellDef="let booking">
				{{ booking.flight?.balloon?.name }}
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="flight_location">
			<mat-header-cell *matHeaderCellDef>
				{{ 'BOOKINGS.flight_location' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell *matCellDef="let booking">
				{{ booking.flight?.location?.name }}
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="flight_pilot">
			<mat-header-cell *matHeaderCellDef>
				{{ 'BOOKINGS.flight_pilot' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell *matCellDef="let booking">
				{{ booking.flight?.pilot?.name }}
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="totalAmount">
			<mat-header-cell *matHeaderCellDef>
				{{ 'BOOKINGS.totalAmount' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell *matCellDef="let booking">
				<strong>{{ booking?.passengerCounters?.totalAmount }} </strong>
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="paid_amount">
			<mat-header-cell *matHeaderCellDef>
				{{ 'BOOKINGS.paid_amount' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell *matCellDef="let booking">
				{{ booking?.passengerCounters?.paidAmount }}
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="openAmount">
			<mat-header-cell *matHeaderCellDef>
				{{ 'BOOKINGS.openAmount' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell *matCellDef="let booking">
				<div *ngIf="booking?.passengerCounters.openAmount !== 0" style="color: red">{{ booking?.passengerCounters?.openAmount }}</div>
				<div *ngIf="booking?.passengerCounters.openAmount === 0">{{ booking?.passengerCounters?.openAmount }}</div>
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="up">
			<mat-header-cell *matHeaderCellDef [ngStyle]="getCellStyles(80, 80, 80)">UP</mat-header-cell>
			<mat-cell *matCellDef="let booking" (click)="$event.stopPropagation()" [ngStyle]="getCellStyles(80, 80, 80)">
				<button *ngIf="booking.bookingCode" mat-icon-button (click)="openUrl(booking.upUrl)" [matTooltip]="booking.bookingCode">
					<mat-icon>launch</mat-icon>
				</button>
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="flight_confirmation">
			<mat-header-cell *matHeaderCellDef>
				{{ 'BOOKINGS.flight_confirmation' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell
				*matCellDef="let booking"
				(click)="$event.stopPropagation()"
				[satPopoverAnchor]="p"
				(click)="booking.status === 'ADDE' ? p.toggle() : null"
			>
				<sat-popover #p hasBackdrop xAlign="start" yAlign="start">
					<div style="display: flex; flex-direction: column; gap: 5px" class="sat">
						<button mat-stroked-button (click)="confirmBooking.emit(booking); p.toggle()">
							<mat-icon class="mat-accent-icon">check</mat-icon>
						</button>
						<button mat-stroked-button (click)="confirmMailBooking.emit(booking); p.toggle()">
							<mat-icon class="mat-accent-icon">check</mat-icon><mat-icon>mail</mat-icon>
						</button>
						<button mat-stroked-button (click)="deleteBooking.emit(booking); p.toggle()">
							<mat-icon class="mat-error-icon">clear</mat-icon>
						</button>
					</div>
				</sat-popover>
				<mat-icon *ngIf="booking.status === 'ADDE'">more_vertical</mat-icon>
				<mat-icon
					*ngIf="mergeCandidatesYellow(booking)"
					style="color: #ffca28"
					matTooltip="{{ 'VALIDATIONS.possible_duplicates' | translate | capitalize }}"
					>warning</mat-icon
				>
				<mat-icon
					*ngIf="mergeCandidatesRed(booking)"
					color="warn"
					matTooltip="{{ 'VALIDATIONS.possible_duplicates_with_flights' | translate | capitalize }}"
					>warning</mat-icon
				>
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="mismatchReasons">
			<mat-header-cell style="min-width: 160px" *matHeaderCellDef>{{ 'FLIGHTS.mismatchReasons' | translate | capitalize }}</mat-header-cell>
			<mat-cell style="min-width: 160px" *matCellDef="let booking" (click)="$event.stopPropagation()">
				<div style="display: flex; flex-direction: column">
					@for (reason of booking.mismatchReasons; track reason) {
					<div style="color: red; font-size: 12px">{{ reason | translate | capitalize }}</div>
					}
				</div>
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="messages">
			<mat-header-cell *matHeaderCellDef [ngStyle]="getCellStyles(120, 120, 120)">{{
				'BOOKINGS.messages' | translate | capitalize
			}}</mat-header-cell>
			<mat-cell *matCellDef="let booking" (click)="$event.stopPropagation()" [ngStyle]="getCellStyles(120, 120, 120)">
				<div style="display: flex; gap: 10px; justify-content: center; align-items: center">
					<app-booking-maileventlatest [mailEventLatest]="booking.mailEventLatest"></app-booking-maileventlatest>
					<app-booking-smslatest [smsUnread]="booking.smsUnread" [smsLatest]="booking.smsLatest"></app-booking-smslatest>
				</div>
			</mat-cell>
		</ng-container>

		<!-- action -->
		<ng-container matColumnDef="action">
			<mat-header-cell *matHeaderCellDef>{{ 'APPLICATION.action' | translate | capitalize }}</mat-header-cell>
			<mat-cell *matCellDef="let booking" (click)="$event.stopPropagation()">
				<div *ngIf="screensize >= 5">
					<ng-container *ngTemplateOutlet="actionButtons; context: { booking, flexDirection: 'row' }"></ng-container>
				</div>
				<div *ngIf="screensize < 5">
					<ng-container *ngTemplateOutlet="actionButtonsFab; context: { booking, flexDirection: 'row' }"></ng-container>
				</div>
				<!-- <button *ngIf="screensize < 4" mat-mini-fab [matMenuTriggerFor]="menu">
					<mat-icon>more_vert</mat-icon>
				</button>
				<mat-menu #menu="matMenu">
					<div style="margin: 5px">
						<ng-container *ngTemplateOutlet="actionButtons; context: { booking, flexDirection: 'column' }"></ng-container>
					</div>
				</mat-menu> -->
			</mat-cell>
		</ng-container>
		<ng-template #actionButtons let-booking="booking" let-flexDirection="flexDirection">
			<div [ngStyle]="{ display: 'flex', 'flex-direction': flexDirection, 'flex-wrap': 'wrap', 'gap.px': 10 }">
				<button
					*ngIf="(type === 'flight.all' || type === 'flight.onlyMatching') && !(booking?.flight?.id === flightId)"
					mat-flat-button
					color="accent"
					(click)="addBookingsToFlight.emit([booking])"
				>
					<mat-icon>add</mat-icon>{{ 'FLIGHTS.add' | translate | capitalize }}
				</button>
				<button
					*ngIf="
						(type === 'flight.all' || (type === 'flight.onlyMatching' && countAllBookings() > 1)) && !(booking?.flight?.id === flightId)
					"
					mat-stroked-button
					color="none"
					(click)="addBookingsToFlight.emit(bookingsNotOnFlight())"
				>
					{{ 'FLIGHTS.add_all' | translate | capitalize }}&nbsp;({{ countAllBookings() }})
				</button>
				<button
					*ngIf="(type === 'flight.all' || type === 'flight.onlyMatching') && booking?.flight?.id === flightId"
					mat-stroked-button
					disabled
					color="none"
					(click)="addBookingsToFlight.emit(bookings)"
				>
					{{ 'FLIGHTS.already_added' | translate | capitalize }}
				</button>
				<button
					*ngIf="type === 'bookings'"
					style="width: 120px"
					mat-flat-button
					[disabled]="booking.status !== 'ADDE'"
					(click)="inviteBooking.emit(booking)"
				>
					<mat-icon>sms</mat-icon>{{ 'FLIGHTS.invite' | translate | capitalize }}
				</button>
				<button
					*ngIf="type === 'bookings'"
					mat-flat-button
					[disabled]="booking.status === 'CONF'"
					(click)="confirmBookingToFlight.emit(booking)"
				>
					<mat-icon>check</mat-icon>{{ 'FLIGHTS.confirm' | translate | capitalize }}
				</button>
				<button *ngIf="type === 'bookings'" mat-flat-button color="warn" (click)="removeBookingFromFlight.emit(booking)">
					<mat-icon>remove</mat-icon>{{ 'FLIGHTS.remove' | translate | capitalize }}
				</button>
				<button *ngIf="type === 'flight.payment'" mat-flat-button color="accent" (click)="showPaymentStatusBooking.emit(booking)">
					<mat-icon>euro</mat-icon>{{ 'BOOKINGS.paymentStatus' | translate | capitalize }}
				</button>
			</div>
		</ng-template>
		<ng-template #actionButtonsFab let-booking="booking" let-flexDirection="flexDirection">
			<div [ngStyle]="{ display: 'flex', 'flex-direction': flexDirection,   }">
				<button
					*ngIf="(type === 'flight.all' || type === 'flight.onlyMatching') && !(booking?.flight?.id === flightId)"
					mat-icon-button
					color="accent"
					(click)="addBookingsToFlight.emit([booking])"
					matTooltip="{{ 'FLIGHTS.add' | translate | capitalize }}"
				>
					<mat-icon>add</mat-icon>
				</button>
				<!-- No add all in case of mobile view -->
				<!-- <button
					*ngIf="
						(type === 'flight.all' || (type === 'flight.onlyMatching' && countAllBookings() > 1)) && !(booking?.flight?.id === flightId)
					"
					mat-icon-button
					color="none"
					(click)="addBookingsToFlight.emit(bookingsNotOnFlight())"
					matTooltip="{{ 'FLIGHTS.add_all' | translate | capitalize }}"
				>
					<mat-icon>library_add</mat-icon>
				</button> -->
				<button
					*ngIf="(type === 'flight.all' || type === 'flight.onlyMatching') && booking?.flight?.id === flightId"
					mat-icon-button
					disabled
					color="none"
					(click)="addBookingsToFlight.emit(bookings)"
					matTooltip="{{ 'FLIGHTS.already_added' | translate | capitalize }}"
				>
					<mat-icon>add</mat-icon>
				</button>
				<button
					*ngIf="type === 'bookings'"
					mat-icon-button
					color="accent"
					[disabled]="booking.status !== 'ADDE'"
					(click)="inviteBooking.emit(booking)"
					matTooltip="{{ 'FLIGHTS.invite' | translate | capitalize }}"
				>
					<mat-icon>sms</mat-icon>
				</button>
				<button
					*ngIf="type === 'bookings'"
					mat-icon-button
					color="accent"
					[disabled]="booking.status === 'CONF'"
					(click)="confirmBookingToFlight.emit(booking)"
					matTooltip="{{ 'FLIGHTS.confirm' | translate | capitalize }}"
				>
					<mat-icon>check</mat-icon>
				</button>
				<button
					*ngIf="type === 'bookings'"
					mat-icon-button
					color="warn"
					(click)="removeBookingFromFlight.emit(booking)"
					matTooltip="{{ 'FLIGHTS.remove' | translate | capitalize }}"
				>
					<mat-icon>remove</mat-icon>
				</button>
				<button
					*ngIf="type === 'flight.payment'"
					mat-icon-button
					color="accent"
					(click)="showPaymentStatusBooking.emit(booking)"
					matTooltip="{{ 'BOOKINGS.paymentStatus' | translate | capitalize }}"
				>
					<mat-icon>euro</mat-icon>
				</button>
			</div>
		</ng-template>
		<!-- action mobile -->
		<mat-header-row *matHeaderRowDef="getDisplayedColumns(); sticky: true"></mat-header-row>
		<mat-row *matRowDef="let row; columns: getDisplayedColumns()" (click)="bookingClicked(row)"></mat-row>
	</mat-table>
</div>
