import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { CapitalizePipe } from '../core/pipes/capitalize.pipe';
import { FormatDatePipe } from '../core/pipes/formatDate.pipe';
import { FormatSlotPipe } from '../core/pipes/formatSlot.pipe';
import { MatTableModule } from '@angular/material/table';
import { A11yModule } from '@angular/cdk/a11y';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ColorPickerModule } from '@iplab/ngx-color-picker';

import { TagSelectComponent } from './tag/tag-select/tag-select.component';
import { BalloonBadgeComponent } from './badges/balloon-badge/balloon-badge.component';
import { CrewBadgeComponent } from './badges/crew-badge/crew-badge.component';
import { RegionBadgeComponent } from './badges/region-badge/region-badge.component';
import { BookingCardGroupComponent } from './cards/booking-card-group/booking-card-group.component';
import { BookingCardComponent } from './cards/booking-card/booking-card.component';
import { BookingLogCardComponent } from './cards/booking-log-card/booking-log-card.component';
import { BookingMailCardComponent } from './cards/booking-mail-card/booking-mail-card.component';
import { DateCardComponent } from './cards/date-card/date-card.component';
import { FlightCardComponent } from './cards/flight-card/flight-card.component';
import { BookingMaileventlatestComponent } from './infinite-scroll/booking-maileventlatest/booking-maileventlatest.component';
import { BookingSmslatestComponent } from './infinite-scroll/booking-smslatest/booking-smslatest.component';
import { BookingTableIsComponent } from './infinite-scroll/booking-table-is/booking-table-is.component';
import { FlightBookingsComponent } from './infinite-scroll/flight-bookings/flight-bookings.component';
import { BookingCommentsComponent } from './infinite-scroll/booking-comments/booking-comments.component';
import { FlightCommentsComponent } from './infinite-scroll/flight-comments/flight-comments.component';
import { TagAutocompleteComponent } from './tag/tag-autocomplete/tag-autocomplete.component';
import { FlightFilterComponent } from './infinite-scroll/flight-filter/flight-filter.component';
import { FlightTableIsComponent } from './infinite-scroll/flight-table-is/flight-table-is.component';
import { MainLayoutComponent } from './layouts/main-layout.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { TagNewComponent } from './tag/tag-new/tag-new.component';
import { TagTableComponent } from './tag/tag-table/tag-table.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { IntPhonePipe } from '../core/pipes/intPhone.pipe';
import { GoogleMapsModule } from '@angular/google-maps';
import { BookingPreferredDateComponent } from './infinite-scroll/booking-preferred-date/booking-preferred-date.component';
import { TagThemeDirective } from './tag/tag-theme/tag-theme.directive';
import { DashboardSmsComponent } from './dashboard-sms/dashboard-sms.component';
import { SMSByContactComponent } from '../features/sms/sms-by-contact/sms-by-contact.component';
import { SmsStatusIconComponent } from '../features/sms/sms-status-icon/sms-status-icon.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import localeFr from '@angular/common/locales/fr';
import localeNlBE from '@angular/common/locales/nl-BE';
import localeNlNL from '@angular/common/locales/nl';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeIt from '@angular/common/locales/it';
import localePt from '@angular/common/locales/pt';
import localeCs from '@angular/common/locales/cs';

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const CUSTOM_DATE_FORMAT = {
	parse: {
		dateInput: 'DD/MM/YYYY',
	},
	display: {
		dateInput: 'DD/MM/YYYY',
		monthYearLabel: 'MMMM YYYY',
		dateA11yLabel: 'DD/MM/YYYY',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

registerLocaleData(localeFr);
registerLocaleData(localeNlBE);
registerLocaleData(localeNlNL);
registerLocaleData(localeDe);
registerLocaleData(localeEs);
registerLocaleData(localeIt);
registerLocaleData(localePt);
registerLocaleData(localeCs);
@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		NgxFileDropModule,
		TranslateModule,
		FormsModule,
		ReactiveFormsModule,
		ColorPickerModule,
		DragDropModule,
		MatBadgeModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatCardModule,
		MatDividerModule,
		MatFormFieldModule,
		MatCheckboxModule,
		MatChipsModule,
		MatGridListModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatMenuModule,
		MatSelectModule,
		MatSidenavModule,
		MatSortModule,
		MatTabsModule,
		MatTableModule,
		MatToolbarModule,
		MatPaginatorModule,
		MatSlideToggleModule,
		MatRadioModule,
		MatRippleModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatExpansionModule,
		MatDialogModule,
		MatCheckboxModule,
		MatSliderModule,
		MatStepperModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatAutocompleteModule,
		MatTooltipModule,
		SatPopoverModule,
		GoogleMapsModule,
		NgxMaterialTimepickerModule,
	],
	declarations: [
		CapitalizePipe,
		IntPhonePipe,
		FormatDatePipe,
		FormatSlotPipe,
		TagSelectComponent,
		MainLayoutComponent,
		DashboardSmsComponent,
		SMSByContactComponent,
		SmsStatusIconComponent,
		ToolbarComponent,
		SideMenuComponent,
		DateCardComponent,
		FlightCardComponent,
		BookingCardComponent,
		BookingCardGroupComponent,
		BookingLogCardComponent,
		BookingMailCardComponent,
		FlightTableIsComponent,
		FlightBookingsComponent,
		BookingCommentsComponent,
		FlightCommentsComponent,
		TagAutocompleteComponent,
		FlightFilterComponent,
		BookingTableIsComponent,
		BookingMaileventlatestComponent,
		BookingSmslatestComponent,
		CrewBadgeComponent,
		BalloonBadgeComponent,
		RegionBadgeComponent,
		TagTableComponent,
		TagNewComponent,
		BookingPreferredDateComponent,
		TagThemeDirective,
	],
	exports: [
		CommonModule,
		NgxFileDropModule,
		TranslateModule,
		CapitalizePipe,
		FormatDatePipe,
		FormatSlotPipe,
		IntPhonePipe,
		TagSelectComponent,
		MainLayoutComponent,
		DashboardSmsComponent,
		SMSByContactComponent,
		SmsStatusIconComponent,
		ToolbarComponent,
		SideMenuComponent,
		DateCardComponent,
		FlightCardComponent,
		BookingCardComponent,
		BookingCardGroupComponent,
		BookingLogCardComponent,
		BookingMailCardComponent,
		FlightTableIsComponent,
		FlightBookingsComponent,
		BookingCommentsComponent,
		FlightCommentsComponent,
		TagAutocompleteComponent,
		FlightFilterComponent,
		BookingTableIsComponent,
		BookingMaileventlatestComponent,
		BookingSmslatestComponent,
		CrewBadgeComponent,
		BalloonBadgeComponent,
		RegionBadgeComponent,
		TagTableComponent,
		TagNewComponent,
		A11yModule,
		DragDropModule,
		PortalModule,
		ScrollingModule,
		CdkStepperModule,
		CdkTableModule,
		CdkTreeModule,
		ClipboardModule,
		ColorPickerModule,
		MatBadgeModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatCardModule,
		MatDividerModule,
		MatFormFieldModule,
		MatCheckboxModule,
		MatChipsModule,
		MatGridListModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatMenuModule,
		MatSelectModule,
		MatSidenavModule,
		MatSortModule,
		MatTabsModule,
		MatTableModule,
		MatToolbarModule,
		MatPaginatorModule,
		MatSlideToggleModule,
		MatRadioModule,
		MatRippleModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatExpansionModule,
		MatDialogModule,
		MatCheckboxModule,
		MatSliderModule,
		MatStepperModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatAutocompleteModule,
		MatTooltipModule,
		SatPopoverModule,
		CKEditorModule,
		FormsModule,
		OverlayModule,
		ReactiveFormsModule,
		GoogleMapsModule,
		TagThemeDirective,
		NgxMaterialTimepickerModule,
	],
})
export class SharedModule {}
