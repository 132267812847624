{
	"CHARTS": {
		"last1months": "1m",
		"last3months": "3m",
		"last6months": "6m",
		"last12months": "12m",
		"last24months": "24m"
	},
	"VALIDATIONS": {
		"error": "An unexpected error occured, review your input",
		"minimun_one_adult_or_Child_is_required": "You need minimun one adult or child",
		"error_tenant_does_not_exist": "the operator doesn't exist",
		"not_possible": "this action is not possible",
		"required": "required field",
		"tenant_is_required": "operator is a required field",
		"email_is_required": "email is a required field",
		"wrong_password": "wrong password",
		"check_email": "a password reset link was sent",
		"International_phone_format": "international format required",
		"email": "invalid email address",
		"duplicate_email": "duplicate email address is not allowed",
		"no_integer": "this is not a valid whole number",
		"token_has_expired": "token has expired",
		"wrong_credentials": "wrong credentials",
		"date_later_than_today": "date cannot be earlier than 90 days ago",
		"save_or_cancel_updates": "save or cancel your updates first",
		"warning": "warning",
		"capacity_must_be_between_0_and_30": "capacity must be between 0 and 100",
		"validation_errors": "correct first the errors in the form",
		"mismatch_location": "flight location is not in booking region",
		"mismatch_availability": "flight date mismatch with booking availability",
		"mismatch_start_date": "flight date is earlier than booking start date",
		"mismatch_end_date": "flight date is later than booking end date",
		"mismatch_type": "flight type mismatch with booking type",
		"mismatch_flight": "booked for another flight",
		"missing_function": "This function is expected soon",
		"no_category": "no passenger categories found, go first to settings to add 1 or more categories.",
		"no_region": "no region found, go first to regions to add 1 or more regions.",
		"lift_mismatch": "lift calculation is not possible due to missing data",
		"lift_missing_location": "location is missing",
		"lift_missing_balloon_volume": "envelope volume is unknown",
		"lift_missing_balloon_weight": "balloon weight is unknown or incomplete",
		"lift_missing_pilot": "pilot is unknown",
		"lift_missing_pilot_weight": "pilot weight is unknown",
		"lift_missing_temp_takeoff": "take-off temperature is unknown",
		"lift_missing_pressure": "take-off pressure is unknown",
		"mobile_missing_signature": "not all passengers are checked in",
		"mobile_missing_forecast": "no weather forecast available",
		"mobile_documents": "expiry dates",
		"mobile_doc_insurance": "insurance valid till",
		"mobile_doc_airworthiness": "airworthiness valid till",
		"mobile_doc_license": "pilot license valid till",
		"mobile_clear": "clear",
		"mobile_agree": "agree",
		"mobile_self_sign": "sign X",
		"click_to_fly": "click to fly",
		"pax_good_to_fly": "pax ok",
		"lift_good_to_fly": "lift ok",
		"fuel_good_to_fly": "fuel ok",
		"weather_good_to_fly": "weather ok",
		"docs_good_to_fly": "documents ok",
		"pilot_sign_off": "confirm all parameters and sign for approval. Comments on the parameters can be added first.",
		"agree": "agree",
		"redo": "redo",
		"new_version": "new version available",
		"reload_the_app": "do you want to restart Quick Release now?",
		"new_features_available": "{0} new feature(s) available",
		"show_new_features": "show new features",
		"bookings_with_open_amount": "bookings with open amount",
		"bookings_to_confirm": "to confirm",
		"bookings_bookmarked": "bookmarks",
		"cylinder_capacity_must_be_between_0_and_200": "capacity must be between 0 and 200",
		"cylinder_weight_must_be_between_0_and_200": "weight must be between 0 and 200",
		"unauthorized": "you are not authorized for this operation",
		"disconnected": "connectivity problem, do you have internet?",
		"possible_duplicates": "we have found a possible duplicate booking (the same e-mail address was found in another open booking)",
		"possible_duplicates_with_flights": "we have found a possible duplicate booking an another flight",
		"duplicate_flight": "balloon is already used",
		"duplicate_pilot": "pilot is already planned"
	},
	"HINTS": {
		"add_country_code": "add country code +32...",
		"no_contact_selected": "Select a contact",
		"message_filter": "filter on/off",
		"role_can_only_be_set_after_creation": "the role can only be set after creation",
		"please_wait": "please wait"
	},
	"SKEYES": {
		"ballooning_bulletin": "Ballooning Bulletin",
		"load": "Skeyes login",
		"disclaimer": "The ballooning bulletin is a service of Skeyes",
		"instructions": "Enter your Skeyes login and password to consult the Ballooning Bulletin. Only pilots with a valid license can consult the Ballooning Bulletin. The data is only used to consult the Ballooning Bulletin and is not stored. Skeyes terms of use apply.",
		"username": "Skeyes Username",
		"password": "Skeyes Password",
		"loaded_by": "loaded by",
		"loading_can_take_up_to_15sec": "loading can take up to 15 seconds",
		"error": "error loading the ballooning bulletin",
		"slot_warning": "this bulletin is probably not valid for this flight"
	},
	"APPLICATION": {
		"domain": "domain",
		"Du": "nederlands (Nederland)",
		"Nl": "nederlands (België)",
		"Fr": "français (french)",
		"En": "english",
		"Es": "español (spanish)",
		"Cs": "cesky (czech)",
		"It": "Italiano (Italian)",
		"De": "Deutsch (German)",
		"Pt": "Português (portuguese)",
		"Lv": "Latviešu (Latvian)",
		"date": "date",
		"name": "name",
		"call_sign": "call sign",
		"capacity": "capacity",
		"flight_count": "flights",
		"sku": "sku",
		"unit_count": "units",
		"unit_cost": "unit price",
		"send_sms": "send sms",
		"receive_sms": "received sms",
		"sms_count": "sms",
		"payments": "payments",
		"running_month": "running month",
		"history_balloons": "history balloons",
		"history_sms": "history sms",
		"total_cost": "price",
		"sorry": "sorry",
		"flights": "flights",
		"calendar": "calendar",
		"bookings": "bookings",
		"vouchers": "vouchers",
		"balloons": "balloons",
		"crew": "crew and pilots",
		"regions": "regions",
		"locations": "locations",
		"reports": "reports",
		"admin": "admin",
		"releasenotes": "release notes",
		"subscription": "subscription",
		"log_sms_in": "inbound sms",
		"log_sms_out": "outbound sms",
		"settings": "settings",
		"cylinders": "cylinders",
		"language": "language",
		"sms": "sms",
		"help": "help",
		"search": "search",
		"live_filter_off": "Use the search button to search. If you want more than 25 search results, then live search is disabled",
		"add_booking": "add booking",
		"add_voucher": "add voucher",
		"add_flight": "add flight",
		"privacy_policy": "privacy policy",
		"terms": "terms",
		"sign in to use your Quick Release Account": "sign in to use your Quick Release Account",
		"your username and password didn't match": "your username and password didn't match",
		"please try again": "please try again",
		"username": "username",
		"password": "password",
		"sign_in": "sign in",
		"send_PIN": "send PIN",
		"use_PIN": "use PIN",
		"pin_successfully_sent": "PIN successfully sent",
		"use_password": "use password",
		"send_email": "send email",
		"email": "email",
		"send_email_to": "select addressee",
		"select_template": "select template",
		"sent": "sent",
		"waiting": "sending",
		"pin": "PIN",
		"sign_out": "sign out",
		"save": "save",
		"save_and_back": "save and back",
		"cancel": "cancel",
		"previous": "previous",
		"delete": "delete",
		"refresh": "refresh",
		"today": "today",
		"classic": "back to old interface",
		"month": "month",
		"week": "week",
		"morning_flights": "morning flights today",
		"evening_flights": "evening flights today",
		"morning_flights_tomorrow": "morning flights tomorrow",
		"yesterday_flights": "evening flights yesterday",
		"action": "action",
		"version": "version",
		"hide_details": "hide details",
		"loads_faster": "hide details will increase the loading speed of the flights",
		"assign": "assign",
		"assigned": "assigned",
		"assign_to": "assign flight to",
		"close": "close",
		"apps": "resources"
	},
	"CALENDAR": {
		"waiting_list": "waiting list",
		"no_bookings_yet": "no bookings yet",
		"drag_and_drop_reservations_here_to_add_to_this_flight": "drag and drop bookings here to add to this flight",
		"drag_and_drop_reservations_here_to_remove_from_this_flight": "drap and drop bookings here to remove from the flight",
		"good_job": "good job!",
		"all_bookings_are_added_to_a_flight": "all bookings were added to a flight",
		"you_cannot_drop_bookings_here": "you cannot drop bookings here",
		"show_calendar_crew": "show calendar of crew",
		"set_month": "set month",
		"clear_month": "delete all",
		"plan": "plan",
		"refetch": "refresh"
	},
	"ACTORS": {
		"COM": "CPL",
		"NCO": "PPL",
		"NOL": "no license",
		"NOA": "no access",
		"OPR": "operator",
		"CRE": "pilot/crew (only assigned flights)",
		"REP": "report viewer",
		"ASS": "Operator assistant (flights & bookings)",
		"MET": "meteo (only flights)",
		"user_role": "user role",
		"name": "name",
		"phone": "phone (international format: +32460205645)",
		"email": "email",
		"used_for_login": "used for login",
		"emailNotifications": "email notifications",
		"emails_from_the_system_will_be_send_to_this_address": "emails from the system will be send to this address, not the login address",
		"driver_license": "driver license",
		"pilot_license": "pilot license ",
		"ground_crew": "ground crew",
		"language": "language",
		"confirm_message": "are you sure you want to delete?",
		"confirm": "confirm",
		"contacts": "contacts",
		"update": "update",
		"delete": "delete",
		"save": "save",
		"add_contact": "add contact",
		"crew": "crew",
		"pilot": "pilot",
		"add_crew": "add crew",
		"national_license": "national license",
		"part_fcl_license": "PART-FCL license",
		"license_group_A": "group A",
		"license_group_B": "group B",
		"license_group_C": "group C",
		"license_group_D": "group D",
		"license_gas": "gas balloon",
		"license_number": "license number",
		"license_expiry_date": "license / OPC expiry date",
		"license_extra_crew": "license extra crew",
		"medical_expiry_date": "medical expiry date",
		"weight": "weight",
		"warning_not_current": "pilot's validity expires within 30 days, check the expiry dates",
		"error_not_current": "pilot grounded, check the expiry dates",
		"assigned_to_flight": "assigned to flight",
		"your_flight": "your flight",
		"your_flights": "your flights",
		"pilots": "pilots"
	},
	"BALLOONS": {
		"name": "name",
		"call_sign": "call sign",
		"capacity": "seats",
		"total_weight": "total weight",
		"balloons": "balloons",
		"confirm_message": "are you sure you want to delete?",
		"confirm": "confirm",
		"update": "update",
		"delete": "delete",
		"save": "save",
		"add_balloon": "add balloon",
		"group": "group",
		"MLM": "MTM (Minimum Landing Mass)",
		"MTOM": "MTOM (Maximum Take-Off Mass)",
		"min_fuel": "average consumption (Liter per hour)",
		"fuel": "consumption at 20°C and maximum number of passengers",
		"envelope": "envelope",
		"envelope_manufacturer": "envelope manufacturer",
		"envelope_type": "envelope type",
		"envelope_volume": "volume envelope",
		"envelope_weight": "envelope weight",
		"envelope_hours": "envelope hours",
		"envelope_inspection_date": "inspection valid until",
		"balloon_temp": "envelope temperature",
		"burner": "burner",
		"burner_manufacturer": "burner manufacturer",
		"burner_type": "burner type",
		"burner_weight": "burner weight",
		"cylinder_hint": "Save the balloon first and then add cylinders. Make sure you first create the cylinders in the system. The same cylinders can be used on multiple balloons",
		"cylinder": "cylinders",
		"cylinder_manufacturer": "manufacturer",
		"cylinder_volume": "volume",
		"cylinder_count": "number",
		"cylinder_empty_weight": "empty weight",
		"cylinder_full_weight": "full weight",
		"cylinder_total_weight": "total weight",
		"basket": "basket",
		"basket_manufacturer": "basket manufacturer",
		"basket_type": "basket type",
		"basket_weight": "basket weight",
		"insurance_company": "insurance company",
		"insurance_due_date": "insurance expiry date",
		"add_cylinder": "add cylinder",
		"error_not_current": "Balloon grounded, update the expiry dates",
		"warning_not_current": "Balloon expires in 30 days, update the expiry dates"
	},
	"VOUCHERS": {
		"status": "status",
		"create_booking": "create booking",
		"available": "available",
		"used": "used",
		"expired": "expired",
		"voucher_type": "type",
		"show_all_types": "show all types",
		"voucher_reference": "reference",
		"issue_date": "issue date",
		"expiry_date": "expiry date",
		"contactName": "contact name",
		"contactPhone": "phone",
		"contactLanguage": "language",
		"contactEmail": "e-mail",
		"contact_city": "city",
		"recipient_info": "details of the person who will receive and use the voucher",
		"buyer_info": "details of the person who purchased the voucher",
		"details_info": "specific information about the voucher itself",
		"buyer": "purchased by",
		"recipient": "recipient",
		"recipientName": "name",
		"recipientPhone": "phone",
		"recipientLanguage": "language",
		"recipientEmail": "email",
		"recipientAddress": "address",
		"recipientPostalCode": "ZIP code",
		"recipientCity": "city",
		"recipientCountry": "country",
		"details": "details",
		"comments": "comments",
		"paymentAmount": "amount",
		"paymentStatus": "payment status",
		"paymentDate": "payment date",
		"adults": "adults",
		"children": "children",
		"external": "external",
		"external_source": "source",
		"tickets": "tickets",
		"voucher": "voucher",
		"available_vouchers": "available vouchers",
		"confirm_message": "are you sure you want to delete this voucher?",
		"unpaid_vouchers": "Warning! unpaid vouchers",
		"IMPORT": {
			"import": "import",
			"upload_csv": "upload CSV",
			"upload_valid_csv_with_this_header": "upload a valid CSV with the following header",
			"example_with_2_adults": "example of a voucher with 2 adults",
			"choose_file": "choose file",
			"check_file": "check file",
			"check_errors": "check errors",
			"not_a_valid_CSV": "this is not a valid CSV",
			"valid_CSV": "no validation errors",
			"click_import": "click import",
			"number_of_vouchers": "number of vouchers",
			"import_status": "import status",
			"imported_succesfully": "successfully imported",
			"imported_with_errors": "errors during import",
			"finish": "finish",
			"row": "row",
			"error_more_less_columns": "more or less columns than expected",
			"error_header": "error in the header",
			"error_contact_name": "error in contactName, this field is mandatory",
			"error_contact_phone": "error in contactPhone, this field is mandatory and in the format M:+32...",
			"error_contact_language": "error in contactLanguage, this field is mandatory",
			"error_voucher_type": "error in voucher type, this field is mandatory",
			"error_voucher_reference": "error in voucher reference, this field is mandatory",
			"error_issue_date": "error in issue date, this field is mandatory and in the format YYYY-MM-DD",
			"error_expiry_date": "error in expiry date, this field is mandatory and in the format YYYY-MM-DD",
			"error_payment_date": "error in payment date, this field is mandatory and in the format YYYY-MM-DD",
			"error_payment_status": "error in payment status, this field is mandatory and accepted values are OPEN or PAID",
			"error_payment_type": "error in payment type, this field is mandatory and accepted values are NOPA, CASH, WIRE, BANC, CRED, ONLI",
			"error_adults": "error in adults, this must be a number",
			"error_children": "error in children, this must be number",
			"error_import": "error during import",
			"succes_import": "imported successfully",
			"cancel": "cancel"
		}
	},
	"BOOKINGS": {
		"id": "id",
		"availability": "availability",
		"PERI": "availability any date",
		"DATE": "availability on a specific date",
		"MOR": "morning",
		"EVE": "evening",
		"mo": "mo",
		"tu": "tu",
		"we": "we",
		"th": "th",
		"fr": "fr",
		"sa": "sa",
		"su": "su",
		"save": "save",
		"child": "child",
		"age": "age",
		"transport": "transport",
		"age_amount": "years",
		"name": "name",
		"weight": "weight",
		"luggage": "luggage",
		"paymentType": "payment type ",
		"paymentReference": "payment reference ",
		"paymentAmount": "payment amount",
		"paymentStatus": "payment status",
		"paymentDate": "payment date",
		"delete": "delete",
		"OPEN": "open",
		"UNK": "added",
		"INV": "invited",
		"CON": "confirmed",
		"completed": "completed",
		"CAN": "cancelled",
		"CPP": "commercial private passengers",
		"COP": "commercial passengers",
		"NCP": "non commercial passengers",
		"booking_date": "booking date",
		"days_expired": "{{ d }} days expired",
		"startDate": "start date",
		"endDate": "end date",
		"status": "status",
		"bookingType": "booking type",
		"bookingCode": "booking reference",
		"bookingCodeHint": "click to launch the app for passengers",
		"bookingCodeMissingEmail": "without email, the passengers have no access to the booking in the app for passengers",
		"commentsTitle": "comments",
		"comments": "internal comments",
		"pax_comments": "comments from passenger",
		"region": "region",
		"source": "source",
		"new_source": "new source",
		"you_can_manage_all_sources_in_settings": "you can manage all sources in settings",
		"source_validation": "source is mandatory, min 3, max 50 characters and must be unique",
		"source_list_validation": "choose a source from the list or create a new source first",
		"source_list_empty": "no sources found, click new source",
		"locationType": "location",
		"REGI": "all locations in a region",
		"FIXE": "specific location in a regio",
		"PRIV": "private location",
		"contactPerson": "contact person",
		"contactName": "contact name ",
		"contactPhone": "contact phone (international format: +32460205645)",
		"contactEmail": "contact email ",
		"contactLanguage": "contact language ",
		"action_needed": "action needed",
		"answer_flight_yes_no": "answer FLIGHT YES or FLIGHT NO",
		"flight_yes": "FLIGHT YES",
		"flight_no": "FLIGHT NO",
		"PAID": "paid",
		"REFUNDED": "refunded",
		"not_paid": "to pay",
		"CASH": "cash",
		"COUP": "voucher (in system)",
		"VOUC": "voucher",
		"NOPA": "no payment",
		"WIRE": "wire transfer",
		"BANC": "bancontact",
		"CRED": "credit card",
		"ONLI": "online",
		"INVO": "invoice",
		"only_available_with_paymenttype_voucher": "only available with payment type voucher",
		"bookings": "bookings",
		"booking": "booking",
		"confirm": "confirm",
		"update": "update",
		"contact": "contact",
		"passengers": "passengers",
		"cancel": "cancel",
		"next": "next",
		"flightDate": "flight date",
		"flightPeriod": "flight period",
		"flight_balloon": "flight balloon",
		"flight_location": "flight location",
		"flight_pilot": "flight pilot",
		"add_passenger": "add passenger",
		"messages": "messages",
		"no_bookings_found": "no bookings found",
		"inviteCount": "invites",
		"confirm_message": "are you sure you want to delete this booking?",
		"always_available": "always available",
		"limited_availability": "limited availability",
		"end_before": "vlucht voor",
		"passenger": "passenger",
		"details": "details",
		"payment_details": "payments",
		"address_details": "address info",
		"disability": "assistance required",
		"category": "category",
		"address": "address",
		"postalCode": "postal code",
		"city": "city",
		"country": "country",
		"signature": "signature",
		"added_flight": "selected flight",
		"select_flight": "select flight",
		"no_flight_added": "no flight selected",
		"no_flight_data": "flight data is only shown if the location and date is fixed",
		"available_flights": "available flights",
		"no_available_flights": "no flights available",
		"available_balloons": "available balloons",
		"no_available_balloons": "no balloons available",
		"totalAmount": "total amount",
		"paid_amount": "paid",
		"openAmount": "open amount",
		"prepayment": "prepayment",
		"prepaymentDate": "prepayment date",
		"passengers_to_pay": "passenger(s) to pay",
		"mark_paid": "mark as paid",
		"remove_from_passengerlist": "remove from passenger list",
		"add_to_passengerlist": "add additional passenger",
		"checkin_passengers": "back to check in",
		"view_checked_in": "show checked in pax",
		"hide_checked_in": "hide checked in pax",
		"import": "import",
		"upload_csv": "upload CSV",
		"upload_valid_csv_with_this_header": "upload a valid CSV file with the following header",
		"example_in_region_with_id_1_with_2_adults_and_0_children": "example of a booking with 2 adults in region with Id 1",
		"choose_file": "select file",
		"check_file": "check file",
		"check_errors": "correct the following errors",
		"not_a_valid_CSV": "this is not a valid file",
		"valid_CSV": "there are no errors",
		"click_import": "click to import",
		"number_of_bookings": "number of bookings",
		"number_of_objects_upcoming": "upcoming bookings/flights",
		"number_of_objects_history": "historical bookings/flights",
		"import_status": "import status",
		"imported_succesfully": "succesfully imported",
		"imported_with_errors": "errors during import",
		"finish": "finish",
		"row": "row",
		"error_more_less_columns": "more or less columns than expected",
		"error_header": "error in the header",
		"error_contact_name": "error in contactName, this field is mandatory",
		"error_contact_phone": "error in contactPhone, this field is mandatory and in the format M:+32...",
		"error_contact_language": "error in contactLanguage, this field is mandatory",
		"error_region": "error in region, this field is mandatory and must have the ID of the region",
		"error_adults": "error in adults, this must be a number",
		"error_children": "error in children, this must be a number",
		"errorImport": "error during import",
		"succesImport": "imported succesfully",
		"flight_confirmation": "flight confirmation",
		"date_filter_is_yyyy-mm-dd": "date filter = yyyy-mm-dd",
		"select_your_view": "select your view",
		"VIEW": {
			"standard": "standard view",
			"openAmount": "all open amounts",
			"toConfirm": "to confirm",
			"bookmarked": "bookmarked",
			"availableThisYear": "available this year",
			"availableNext30Days": "available next 30 days",
			"flyingThisYear": "flying this year",
			"flyingNext30Days": "flying next 30 days",
			"withoutFlight": "without flight"
		},
		"flight_confirmed": "flight confirmed",
		"invited_waiting_answer": "invited, waiting answer",
		"MERGE": {
			"merge": "merge",
			"load": "load",
			"restart": "restart",
			"select_booking": "select bookings",
			"select_booking_hint": "the left side booking will be deleted, the right side booking will be saved",
			"merge_booking": "merge bookings",
			"merge_booking_hint": "give your consent before you can save and delete",
			"booking_id_to_delete": "booking id to delete",
			"booking_id_to_merge": "booking id to merge",
			"error_loading": "error during loading, select another id or restart",
			"warning_to_switch": "warning: you choose to delete the most recent reservation",
			"save_and_delete": "save this reservation and delete",
			"confirm_to_delete": "consent to delete",
			"replace": "replace",
			"add": "add",
			"undo": "undo",
			"fields_are_identical": "fields are identical",
			"remove": "remove"
		},
		"LOG": {
			"logbook": "logbook",
			"outbox": "sent emails",
			"only_logs_from_UI": "logging from Quick Release (not from UP or from website)",
			"ADDE": "added to the flight",
			"REMO": "removed from the flight",
			"CONF": "confirmed for the flight",
			"cancel": "flight cancelled, moved to booking list",
			"COMP": "flight executed, moved to history (completed)",
			"INVI": "invited by SMS to the flight",
			"CREA": "booking created in the system",
			"update": "booking updated one or more fields",
			"DELE": "booking deleted from the system",
			"MAIL": "email sent",
			"view_email": "show email",
			"flight": "flight"
		},
		"invoice_name": "invoice name",
		"vat_number": "VAT number"
	},
	"COMMUNICATIONS": {
		"sent_at": "sent at",
		"phone": "phone",
		"name": "name",
		"text": "text",
		"status": "status",
		"message_id": "message id",
		"message_price": "message price",
		"network": "network",
		"remaining_balance": "remaining balance",
		"received_at": "received at",
		"msisdn": "msisdn",
		"message_timestamp": "message timestamp",
		"keyword": "keyword",
		"pilot_invitation_sms": "SMS to pilots",
		"crew_invitation_sms": "SMS to crew",
		"passenger_invitation_sms": "SMS to passengers",
		"cancel_sms": "SMS to cancel",
		"reminder_sms": "SMS to remind",
		"sms_in": "incoming sms",
		"messages": "messages",
		"flight": "flight",
		"booking": "booking",
		"sms_out": "outgoing sms",
		"to": "to",
		"unanswered_messages": "unanswered messages",
		"mark": "mark",
		"mark_all": "mark all",
		"mark_paid": "mark paid",
		"mark_not_paid": "mark not paid",
		"messages_as_read": "messages as read",
		"you": "you",
		"mail_copy": "Email PDF",
		"action_succeed": "action successful",
		"accepted": "accepted",
		"delivered": "delivered",
		"failed": "failed to deliver",
		"unknown": "unknown error",
		"retry": "retry later",
		"wrongnumber": "wrong number",
		"none": "none"
	},
	"DASHBOARDS": {
		"flights": "flights",
		"no_flights": "no flights",
		"upcoming": "upcoming",
		"historical": "finished",
		"this_month": "this month",
		"bookings": "open bookings",
		"vouchers": "vouchers",
		"open": "open",
		"completed": "completed",
		"expired": "expired",
		"idea_voting": "idea voting",
		"search": "search",
		"payments": "payments",
		"charts": "charts",
		"flights_of_today_and_tomorrow_morning": "all flights of today and tomorrow morning",
		"flights_of_next_7_days": "all flights of next 7 days",
		"bookings_to_confirm_bookmarked_and_of_today_or_tomorrow_morning": "all bookings to confirm, bookmarked or booked for today or tomorrow morning, the new reservations or with a dashboard-tag",
		"basic_charts_of_reservations_and_flights": "basic charts of bookings and flights",
		"charts_are_no_longer_shown_on_the_dashboard_please_navigate_to_reports": "charts are no longer shown on the dashboard, please navigate to reports",
		"navigate_to_reports": "Navigate to reports",
		"most_recent_sms_per_contact": "most recent conversations",
		"show_all_messages": "all",
		"show_messags_from_this_flight": "show only this flight",
		"show_messages_from_this_booking": "show only this booking",
		"show_unread": "unread"
	},
	"FLIGHTS": {
		"save": "save",
		"unknown": "unknown",
		"CON": "confirmed",
		"invited_by_sms": "invited by sms",
		"DEC": "declined ",
		"MOR": "morning",
		"EVE": "evening",
		"CPF": "commercial private flight",
		"COF": "commercial flight",
		"NCF": "non commercial flight",
		"PRI": "not shared",
		"PUB": "shared",
		"SCH": "scheduled",
		"REA": "ready",
		"MARK": {
			"SCH": "schedule",
			"REA": "set ready",
			"CAN": "cancel",
			"FIN": "finish"
		},
		"INFO": {
			"SCH": "get your stuff together and set ready to fly",
			"REA": "well done! This flight is ready to release",
			"CAN": "this flight is cancelled, you can remove it from the system",
			"FIN": "this flight is done, great job!"
		},
		"checkin": "check in",
		"CAN": "cancelled",
		"FIN": "finished",
		"balloon": "balloon",
		"date": "date",
		"period": "period",
		"event": "event",
		"hour": "hour",
		"sunrise": "sunrise",
		"sunset": "sunset",
		"flight_type": "flight type",
		"location": "location ",
		"visibility": "visibility",
		"web": "web",
		"status": "status",
		"comment": "comment",
		"pilot": "pilot ",
		"pilot_status": "status",
		"action_needed": "action needed",
		"ground_crew": "crew ",
		"pilot_and_crew": "pilot and crew",
		"not_enough_data": "not enough data",
		"answer_pilot_yes_no": "answer PILOT YES or PILOT NO.",
		"pilot_yes": "PILOT YES",
		"pilot_no": "PILOT NO",
		"Flight date cannot be set in the past": "Flight date cannot be set in the past",
		"answer_crew_yes_no": "Answer CREW YES or CREW NO.",
		"crew_yes": "CREW YES",
		"crew_no": "CREW NO",
		"flights": "flights",
		"flight_bookings": "flight bookings",
		"UNK": "added",
		"INV": "invited by sms",
		"invite_all": "SMS invite all",
		"inviteCount": "invites",
		"invite_count_1": "invite",
		"mo": "mo",
		"tu": "tu",
		"we": "we",
		"th": "th",
		"fr": "fr",
		"sa": "sa",
		"su": "su",
		"invite": "send sms",
		"mail": "email",
		"reply": "Type your reply message here",
		"confirm": "confirm",
		"remove": "remove",
		"contactName": "contact name",
		"bookingType": "booking type",
		"region": "region",
		"passengers": "passengers",
		"no_bookings_selected": "no bookings selected",
		"matching_bookings": "bookings",
		"mismatching_bookings": "bookings on another flight",
		"mismatchReasons": "flight matching",
		"add_all": "add all",
		"add": "add",
		"already_added": "already added",
		"messages": "messages",
		"calendar": "calendar",
		"download_manifest": "download manifest",
		"send_sms_reminder": "send sms reminder",
		"update": "update",
		"are_you_sure": "are you sure",
		"reminder_sms_will_be_sent": "a reminder SMS will be sent",
		"edit_sms": "edit SMS",
		"preview_sms": "preview",
		"send": "send",
		"send_to_pax": "send to PAX",
		"send_to_pax_and_crew": "send to PAX and CREW",
		"send_up_url_with_sms": "send UP url with SMS",
		"sent": "sent",
		"message_has_been_sent": "message has been sent",
		"message_has_not_been_sent": "message has not been sent",
		"not_sent": "not sent",
		"not_confirmed_bookings_will_be_removed_from_flight": "the following not confirmed bookings will be removed from the flight",
		"flight_will_be_cancelled": "flight will be cancelled",
		"flight_will_be_finished": "flight will be finished",
		"flight_will_be_reopened": "flight will go back to the planning",
		"yes_cancel_it": "yes cancel it",
		"no_please": "no please",
		"cancel_sms_will_be_sent": "cancel sms will be sent",
		"not_cancelled": "not cancelled",
		"flight_has_not_been_cancelled": "flight has not been cancelled",
		"confirm_message": "are you sure you want to delete this flight?",
		"flight_pilot_in_command": "flight pilot in command",
		"invite_by_sms": "send sms",
		"unsupported_phonenumber": "unsupported phonenumber",
		"sms_unclear": "sms unclear",
		"no_pilot_in_command_selected": "no pilot in command selected",
		"flight_crew": "flight crew",
		"no_crew_selected": "no crew selected",
		"available_pilots_and_crew": "available pilots and crew",
		"select_pilot": "select pilot",
		"select_crew": "select crew",
		"other_flight": "other flight",
		"add_as_crew": "add as crew",
		"add_as_pilot": "add as pilot",
		"warning": "warning",
		"creating_a_flight_can_lead_to_costs": "this action can generate costs",
		"proceed": "proceed",
		"cancel": "cancel",
		"passenger_count": "passenger count",
		"crew_count": "crew count",
		"type": "type",
		"no_pilot": "no pilot",
		"seats": "seats ",
		"delete": "delete",
		"set_ready": "ready",
		"address": "address",
		"postalCode": "ZIP",
		"city": "city",
		"country": "country",
		"manifest": "manifest",
		"certificates": "certificates",
		"takeoffTime": "takeoff time",
		"landingLocation": "landing location",
		"landingDetails": "landing details",
		"total_weight": "total weight",
		"landingTime": "landing time",
		"flightTime": "fly time",
		"and": "and",
		"name": "name",
		"ref": "ref",
		"amount": "amount (EUR)",
		"weight": "weight",
		"signature": "signature",
		"reminder_sms": "reminder SMS",
		"also_to_pilot_and_crew": "also to pilot and crew",
		"cancel_sms": "cancel SMS",
		"no_flights_found": "no flights found",
		"no_location": "no location",
		"show": "show",
		"hide": "hide",
		"lift": "lift",
		"lift_needed": "lift required",
		"lift_available": "lift available",
		"lift_spare": "spare lift",
		"takeoff_location": "Takeoff Location",
		"temp": "temperature",
		"takeoff_height": "takeoff height",
		"maximum_height": "maximum altitude",
		"temp_takeoff": "takeoff temperature",
		"temp_max": "maximum height temperature",
		"weather_qfe": "takeoff pressure (QNH)",
		"weather_pmax": "maximum height pressure",
		"balloon_volume": "balloon volume",
		"balloon_temp": "max temp envelope",
		"create_add": "add to new flight",
		"weather": "weather",
		"copy_weather_to": "copy weather to",
		"use_meteoblue": "use meteoblue",
		"skeyes_ballooning_bulletin": "skeyes ballooning bulletin",
		"use_skeyes": "use Skeyes",
		"weather_forecast": "weather forecast",
		"no_weather_forecast": "no forecast available",
		"windSummary": "forecast summary",
		"weather_source": "source",
		"weather_forecast_date": "forecast date",
		"vfr_visual": "visibility",
		"vfr_clouds": "clouds",
		"wind_gl": "wind ground level",
		"wind_gl_short": "wind GL",
		"wind_dir": "direction",
		"wind_gl_direction": "wind direction ground level",
		"wind_10": "wind 1000FT",
		"wind_10_direction": "wind direction 1000FT",
		"wind_20": "wind 2000FT",
		"wind_20_direction": "wind direction 2000FT",
		"wind": "wind",
		"clear": "clear",
		"fuel_spare": "spare fuel",
		"planned_flight_time": "planned flight time",
		"spare_flight_time": "spare flight time",
		"maximum_flight_time": "available flight time",
		"select_balloon": "select the balloon",
		"select_location": "select the location",
		"offsets": "change sunrise/sunset offsets",
		"flight_hour_will_be_calculated_based_on_sunset_minus_the_offset_minutes": "the hour of the flight is calculated based on sunset (or sunrise) minus the offset minutes, rounded down to half an hour. Negative values are allowed (in case of sunrise).",
		"offset_sunrise": "offset sunrise (minutes)",
		"offset_sunset": "offset sunset (minutes)",
		"payment_overview": "payment overview",
		"go_back": "go back",
		"traject": {
			"trajectory": "trajectory",
			"custom_flight_profile": "custom flight profile",
			"duration": "duration",
			"duration_is_required": "duration is required",
			"level": "level",
			"level_is_required": "level is required",
			"save": "save",
			"no_trajectory_without_meteo": "no trajectory without meteo",
			"update_custom_profile": "update custom profile",
			"refresh_weather": "refresh weather",
			"talking_to_meteoblue": "talking to meteoblue",
			"refresh_hint": "meteoblue updates their weather model every 12 hours. Faster updates are disabled as they are not beneficial."
		},
		"LANDING": {
			"landing": "landing",
			"landing_details": "details",
			"map": "map",
			"no_details": "no details filled in",
			"landing_data": "landing data can only be changed in the mobile check-in screen for now",
			"refuelVolume": "gas refuel volume"
		},
		"FILTER": {
			"date_range_filter": "date range filter",
			"from": "from",
			"till": "till",
			"excluded": "excluded",
			"no_date_filter_active": "no date filter active",
			"enter_a_date_range": "enter a date range",
			"start_date": "start date",
			"end_date": "eind date",
			"month": "month",
			"week": "week",
			"day": "day",
			"flights": "flights"
		},
		"flight_no_bookings": "this flight has no bookings yet",
		"mor_flight": "morning flight",
		"eve_flight": "evening flight",
		"mor_flights": "morning flights",
		"eve_flights": "evening flights",
		"no_mor_flights": "no morning flights",
		"no_eve_flights": "no evening flights",
		"responded_pilots": "responded pilots",
		"delete_flight": "delete flight",
		"toggle_visibility": "change visibility",
		"tags": "tags",
		"COPY": {
			"copy_flight": "copy flight",
			"select_multiple_dates": "select multiple dates to copy the same flight with the same location, balloon, pilot and crew",
			"selected_dates": "selected dates",
			"no_tags": "no tags",
			"copy": "copy"
		},
		"PILOTANDCREW": {
			"selected": "selected",
			"most_used": "mais usado",
			"this_is_the_most_used_pilot_and_crew": "este é o piloto e a tripulação mais utilizados para este balão",
			"accept": "aceitar",
			"all_pilots_and_crew": "todos os pilotos e tripulação",
			"select": "selecionar"
		}
	},
	"MANIFEST": {
		"fuel_consumption": "fuel consumption",
		"fuel_needed": "fuel required",
		"fuel_available": "fuel available",
		"load_chart_and_passenger_list": "Load Calculations and Passenger List",
		"passenger_list": "Passenger List",
		"weather_conditions": "Weather Conditions",
		"fuel_calculations": "Fuel Calculations",
		"load_chart": "Load Calculations",
		"after_flight": "After Flight",
		"visible_defects": "Visible Defects",
		"yes_no": "Yes/No",
		"date": "date",
		"signature": "signature",
		"defect": "defect",
		"action": "action",
		"certificate": "certificate",
		"authority": "authority",
		"initials": "initials",
		"confirmation": "this confims that the specified actions were executed according PART-ML and that the aircraft is declared as ready for the next flight."
	},
	"LOCATIONS": {
		"name": "name",
		"description": "description",
		"region": "region",
		"address": "address",
		"postalCode": "postal code",
		"city": "city",
		"country": "country",
		"height": "takeoff elevation",
		"maximum height": "max altitude",
		"locations": "locations",
		"confirm_message": "are you sure you want to delete?",
		"confirm": "confirm",
		"update": "update",
		"delete": "delete",
		"regions": "regions",
		"save": "save",
		"add_location": "add location",
		"add_region": "add region",
		"hot_airfield": "hot airfield",
		"coordinates": "coordinates",
		"geolocate": "geolocate",
		"no_results": "no results",
		"contact": "contact",
		"takeoff_location": "takeoff Location",
		"takeoff_location_subtitle": "The coordinates of the takeoff location are used for the weather forecast, trajectory and lift calculation.",
		"meetingpoint": "meeting point",
		"meetingpoint_subtitle": "The address of the meeting point is communicated to the passengers (UP).",
		"same_as_takeoff": "same as takeoff location",
		"meetingpointAddress": "meeting point address",
		"CONTACT": {
			"name": "name",
			"type": "type",
			"comment": "comment",
			"phone": "phone",
			"email": "email",
			"when_to_contact": "when to contact",
			"how_to_contact": "how to contact",
			"NOMSG": "no message",
			"MAIL": "email",
			"SMS": "sms",
			"mail": "during the preparation of the flight, you will be able to send an email to the contact person",
			"sms": "during the preparation of the flight, you will be able to send an sms to the contact person",
			"no_message": "no message will be sent to the contact person",
			"add_sms_template": "add sms template",
			"add_mail_template": "add email template",
			"mail_template": "email template",
			"sms_template": "sms template",
			"warning_email": "you did not send an email to the contact person",
			"warning_sms": "you did not send an sms to the contact person"
		}
	},
	"FORECAST": {
		"no_mobile": "Meteo is currently only supported on bigger screens",
		"select_favorite": "Select a location as favorite to show the meteoblue weather forecast",
		"no_forecast": "No meteo available for this location. Check latitude and longitude",
		"remove_favorite": "remove favorite",
		"mark_favorite": "mark favorite",
		"favorite_description": "this location is your favorite location. The meteoblue weather forecast is available on the dashboard for this location.",
		"non_favorite_description": "this location is not your favorite location. Mark it is your favorite to show the meteoblue weather forecast on the dashboard",
		"meteo": "meteo",
		"time": "time",
		"summary": "summary"
	},
	"REGIONS": {
		"name": "name",
		"locations": "locations",
		"no_locations_yet": "no locations yet",
		"name_required": "name required",
		"change_color": "click here to change the region color",
		"reset_color": "reset color",
		"confirm_color": "confirm color",
		"cancel_color": "cancel color"
	},
	"EVENTS": {
		"new_event": "new event",
		"information": "add an event on a specific date or load the public holidays",
		"new_crew_event": "new appointment",
		"crew_information": "add a busy appointment (unavailable to fly or crew)",
		"holidays": "holidays",
		"load_public_holidays_of_year_from_country": "load public holidays of {0} from {1}",
		"date": "date",
		"description": "description",
		"save": "save",
		"cancel": "cancel",
		"required": "required",
		"crew": "crew",
		"NO": "not available",
		"YES": "available",
		"MAYBE": "maybe available",
		"UNK": "availability unknown"
	},
	"SETTINGS": {
		"user_default_language": "user default language",
		"submit": "submit",
		"preferences_language": "language",
		"preferences_sms": "SMS",
		"smsTitle": "title",
		"smsTemplate": "template",
		"pilotInvitationSms": "pilot invitation SMS",
		"example": "example",
		"update": "update",
		"crewInvitationSms": "crew invitation SMS",
		"passengerInvitationSms": "passenger invitation SMS",
		"cancelSms": "cancel SMS",
		"reminderSms": "reminder SMS",
		"locationContactSms": "location contact SMS",
		"emailTitle": "title",
		"no_emailTitle": "no title",
		"emailType": "type",
		"emailTo": "email to",
		"emailSubject": "subject",
		"emailBody": "body",
		"emailAttachments": "attachments",
		"emailTest": "email test",
		"mailFromEmail": "email account address",
		"mailFromName": "Email from name",
		"mailReplyToEmail": "Email Reply To Email",
		"mailAccountantName": "accountant's email name",
		"mailAccountantEmail": "accountant's email address",
		"bookingConfirmEmail": "Email confirmation booking",
		"bookingFlightCancelEmail": "Email end of flight",
		"bookingFlightConfirmEmail": "Email passengers of a flight",
		"crewFlightEmail": "Email flight details for crew",
		"crewOtpEmail": "Email login for crew",
		"pilotFlightEmail": "Email flight details for pilot",
		"flight_email": "Email flight details",
		"locationContactEmail": "Email contact on location",
		"flight_email_passengers": "Email passengers",
		"flight_sms_passengers": "SMS passengers",
		"load_default": "load a standard text",
		"passenger_categories": "passenger categories",
		"category_name": "name",
		"category_description": "description",
		"category_child": "child",
		"category_price": "price",
		"category_default": "default",
		"delete": "delete",
		"mobile_questions": "passenger questions (mobile)",
		"question": "question",
		"kind": "kind",
		"active": "active",
		"title": "title",
		"description": "description",
		"language": "language",
		"texts": "texts",
		"name": "name",
		"address": "address",
		"email": "email",
		"weight": "weight",
		"luggage": "luggage",
		"child": "child",
		"accompany": "accompany",
		"pay": "pay",
		"signature": "signature",
		"optional": "optional",
		"mandatory": "mandatory",
		"order": "order",
		"titles": "questions",
		"descriptions": "question texts",
		"yes": "yes",
		"no": "no",
		"nl": "dutch",
		"fr": "french",
		"en": "english",
		"id": "id",
		"download_sample": "download sample",
		"drag_and_drop_file_here": "drag and drop file here",
		"browse_for_file": "browse for file",
		"operator_settings": "operator settings",
		"operator_backup_and_sync": "backup en sync",
		"operator_bookings": "booking settings",
		"operator_flights": "flight settings",
		"operator_crew": "crew settings",
		"operator_myflight": "UP (Passengers App) settings",
		"export": "export",
		"you_can_activate_a_daily_backup": "you can send a daily export in HTML to a specific e-mail address. As soon as you activate the export, you will receive immediately an export to the given e-mail address, then every day around 4am",
		"daily_backup": "daily export",
		"backup_email": "email address",
		"only_update_when_inactive": "you can only change the email address when the export is inactive",
		"exported_files": "exported files",
		"bookings": "all open bookings",
		"flights": "all planned flights",
		"vouchers": "all vouchers",
		"flights_bookings_passengers": "all planned flights with their bookings and passengers",
		"calendarUrl": "ICS URL for the calendar",
		"you_can_subscribe_to_all_flights_in_your_personal_calendar": "you can subscribe to all flights in your personal calendar",
		"geticsurl": "generate ICS URL",
		"readthedocs": "read the documentation",
		"crew": {
			"title": "Crew",
			"shareBookings": "crew can see the details of the bookings on a flight in the dashboard",
			"shareBookingsHint": "if this is not checked, the crew can only see the number of passengers on a flight in the dashboard",
			"allowFlightSelection": "crew can select flights in the calendar",
			"allowFlightSelectionHint": "if this is not checked, the crew can only see the flights in the calendar, but cannot select them"
		},
		"booking": {
			"title": "bookings",
			"childAge": "child age",
			"disabledPaymentTypes": "disabled payment types",
			"disabledPaymentTypesHint": "if you disable a payment type, it will not be shown in the booking process.",
			"none_of_the_paymentTypes_are_disabled": "none of the payment types are disabled",
			"disabledPaymentTypesSave": "save disabled payment types",
			"childAgeHint": "every passenger with this age or younger will be marked as a child",
			"sources": "sources"
		},
		"flight": {
			"title": "flights",
			"sunriseOffset": "sunrise offset",
			"sunriseOffsetHint": "the flight time is calculated based on sunrise minus the number of offset minutes, rounded down to the half hour.",
			"sunsetOffset": "sunset offset",
			"sunsetOffsetHint": "the flight time is calculated based on sunset minus the number of offset minutes, rounded down to the half hour.",
			"meetingTimeOffset": "meeting time offset (this feature is not yet available)",
			"meetingTimeOffsetHint": "the meeting time for the flight is calculated based on the flight time minus the number of offset minutes. If 0, the meeting time is not displayed.",
			"sunrise": "sunrise",
			"sunset": "sunset",
			"hourMeeting": "meeting time",
			"hour": "flight time",
			"save": "save offsets"
		},
		"myflight": {
			"title": "Quick Release UP (Passengers App)",
			"autoConfirm": "automatically confirm the flight when it is requested via UP",
			"autoConfirmHint": "if this is not checked, the operator has to confirm the flight manually, when added via UP",
			"endOfSeason": "end of season",
			"endOfSeasonHint": "if this is checked, the passengers can no longer access their booking via UP",
			"flights": "Which flights can be booked via UP?",
			"ALL": "all flights",
			"REGIONAL": "only regional flights (flights in the same region as the booking)",
			"NONE": "no flights (passengers can only see their booking)",
			"parameters": "parameters",
			"branding": "branding",
			"logo": "logo",
			"logoHint": "upload your own logo here to show in the UP app",
			"drag_and_drop_file_here": "drag and drop jpg or png file here (max 500kB)",
			"colors": "colors",
			"colorsHint": "click on each color to adjust them to your own branding. You can choose a color from the palette of your logo in the pop-up",
			"example": "example",
			"reset": "reset colors",
			"save": "save colors and logo",
			"primary_background_color": "background color of the app",
			"secondary_background_color": "background color of the tiles",
			"primary_foreground_color": "color of the title and buttons",
			"secondary_foreground_color": "color of the informational text",
			"invalid_file_type_or_size": "invalid file type or size"
		},
		"sms": {
			"footerEnabled": "add footer to SMS",
			"footerEnabledHint": "if this is not checked, the footer will not be added to all invitation SMS"
		}
	},
	"REPORTS": {
		"flights": "flights",
		"bookings_open": "open bookings",
		"bookings": "new bookings",
		"vouchers": "vouchers",
		"payments": "payments",
		"sms": "SMS"
	},
	"MAIL": {
		"hide": "verberg details",
		"show": "toon details",
		"sent": "verzonden"
	},
	"SUBSCRIPTION": {
		"sms_available": "available in subscription",
		"sms_sent": "SMS sent",
		"pct_sms_used": "% of your SMS are used",
		"sms_info": "Contact us to order additional SMS as soon as you used 95% of available SMS in your subscription",
		"sms_warning": "You used 95% or more of your available SMS.",
		"order_sms": "Order now new SMS",
		"sms_ignore_warning": "Hide notification about SMS usage",
		"pct_plan_used": "% of your seats are used",
		"plan_seats_used": "total balloon capacity",
		"plan_valid_until": "valid until",
		"plan_info": "Contact us to switch to a higher plan if the number of seats is too low or to renew your current subscription"
	},
	"TAGS": {
		"delete": "remove tag",
		"enabled": "enabled",
		"enable_tag": "enable tag",
		"disabled": "disabled",
		"disable_tag": "disable tag",
		"save": "save tag",
		"new_tag": "create new tag",
		"tag_name": "tag name",
		"tag_type": "tag type",
		"select_tag_color": "select color",
		"color": "color",
		"tag_already_added": "tag already added",
		"edit": "edit tag",
		"flight_tags": "flight tags",
		"tags": "tags",
		"show_on_dashboard": "show on the dashboard"
	}
}
